import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { KTIcon } from "../../_metronic/helpers";
import { useFormik } from "formik";
import * as Yup from "yup";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";
import { useMutation } from "react-query";
import { addEditWidget } from "../../api";
import { useEffect } from "react";

const TokenGeneration = ({
  handleClose,
  editData,
  show,
  restaurantData,
}: {
  handleClose: (isSubmit: boolean) => void;
  editData: any;
  restaurantData: any;
  show: any;
}) => {

    

    const { mutateAsync: addEditAPI, isLoading: isAddingEditing } = useMutation(
        "add-edit",
        addEditWidget,
      );

  const validationSchema = Yup.object().shape({
    WebsiteURL: Yup.string().required("Website URL is required"), 
  });

  const formik = useFormik({
    initialValues: {
      WebsiteURL: "",
    },
    validationSchema,
    onSubmit: async (values: any) => {
        const AddBodyParams = {
            restaurantId: restaurantData?._id,
            websiteUrls: [values.WebsiteURL]
        }

        const EditBodyParams = {
            restaurantId: restaurantData?._id,
            _id: editData?._id,
            websiteUrls: [values.WebsiteURL]
        }

        const bodyParams = editData?._id ? EditBodyParams : AddBodyParams;

        let response = await addEditWidget(bodyParams);
      if (response?.status) {
        snackActions.success(response?.message ?? "Added/Edited Successfully!");
        formik.resetForm();
        handleClose(true);
      } else {
        snackActions.error(response?.message ?? "Something went wrong!");
      }
    },
  });

  useEffect(() => {
    if(editData){
formik.setFieldValue("WebsiteURL", editData?.websiteUrl)
    }
  }, [editData])

  return (
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-400px"
      onHide={() => handleClose(false)}
      show={show}
      backdrop="static"
    >
      <div className="modal-header">
        <h2>Generate Token</h2>
        {/* begin::Close */}
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={() => handleClose(false)}
        >
          <KTIcon className="fs-1" iconName="cross" />
        </div>
        {/* end::Close */}
      </div>
      
      <div className="p-6">
        <form onSubmit={formik.handleSubmit} noValidate className="form">
          <Container fluid>
            <Row>
              <Col xs={6} className="w-100">
                <label className="form-label fw-bold">Website URL</label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Enter Website URL here"
                  aria-label="WebsiteURL"
                  {...formik.getFieldProps("WebsiteURL")}
                />
                {formik.touched.WebsiteURL && formik.errors.WebsiteURL && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.WebsiteURL as string}
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </Container>
          <div className="w-100 d-flex pt-5 align-items-center mt-3 justify-content-end">
            <Button
              type="button" 
              onClick={() => {
                formik.resetForm();
                handleClose(false);
              }}
              size="lg"
              variant="secondary"
            >
              Cancel
            </Button>
            <div className="w-15px"></div>
            <Button type="submit" size="lg" variant="primary">
              Save
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default TokenGeneration;
