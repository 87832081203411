import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { Box, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useRef, useState } from "react";
import { Card } from "react-bootstrap";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { KTCard } from "../../_metronic/helpers";
import ConfirmEventPopup from "../../_metronic/layout/components/common/ConfirmEventPopup";
import CustomTooltip from "../../_metronic/layout/components/common/CustomTooltip";
import {
  activeDeActiveGolfClub,
  activeInActiveWellness,
  deleteWellness,
  getAllWellnessList,
} from "../../api";
import MuiTable from "../../components/table/MuiTable";
import { charValidate } from "../../components/utility";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";
import useDebounce from "../../hooks/useDebounce";
import { setData } from "../../store/slice/header/headerSlice";
import { useDispatch } from "react-redux";
import AddEditWellness from "./AddEditWellness";
import RestaurantIcon from "@mui/icons-material/GolfCourse";
import SettingsIcon from '@mui/icons-material/Settings';
import StoreIcon from '@mui/icons-material/Store';
import { formatPhoneNumber } from "../../helpers/commonFunctions";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const WellnessList: React.FC = () => {
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [showActiveDeActiveWellnessPopup, setShowActiveDeActiveWellnessPopup] =  useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  

  const [tableOptions, setTableOptions] = useState({
    offset: 0,
    limit: 10,
    hasNextPage: true,
    hasPrevPage: true,
    totalRowCount: undefined,
    type: "",
    golfType: "",
    search: "",
  });
  const tableRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const debounceSearchValue: string = useDebounce(tableOptions?.search, 500);

  const {
    mutateAsync: getWellNessAPI,
    data: wellnessData,
    isLoading: isLoadingWL,
  } = useMutation("restaurant-list", getAllWellnessList);

  const { mutateAsync: activeInActiveWellnessAPI, isLoading: isActiveLoading } =
  useMutation("active-restaurant", activeInActiveWellness);

  const { mutateAsync: deleteWellnessAPI, isLoading: isDeleting } =
    useMutation("delete-wellness", deleteWellness);

  const { mutateAsync: activeDeActiveGolf, isLoading: isActiveDeActiveGolf } =
    useMutation("active-golf", activeDeActiveGolfClub);

  useEffect(() => {
    getRestaurant(tableOptions);
  }, []);

  useEffect(() => {
    getRestaurant({
      ...tableOptions,
      search: debounceSearchValue ? debounceSearchValue : "",
    });
  }, [tableOptions?.type, tableOptions?.golfType, debounceSearchValue]);

  const getRestaurant = async (
    query:
      | {
          offset: number | string;
          limit: number;
          search: string;
        }
      | undefined
  ) => {
    let response = await getWellNessAPI(query);
    if (response?.status) {
      setTableOptions((prevState: any) => {
        return {
          ...prevState,
          offset: response?.data?.offset,
          limit: response?.data?.limit,
          hasNextPage: response?.data?.hasNextPage,
          hasPrevPage: response?.data?.hasPrevPage,
          totalRowCount: response?.data?.totalDocs,
        };
      });
    }
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>, row: any) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };
  const handlePaymentClick = (row: any) => {
    navigate("/category-wellness-details", { state: { restaurantData: row ,  payment: true } });
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleAction = (actionType: string) => {
    switch (actionType) {
      case "activeDeactive":
        setShowActiveDeActiveWellnessPopup(true);
        break;
      case "edit":
        setShowAddEditModal(true);
        break;
      case "delete":
        setShowDeleteConfirmModal(true);
        break;
      case "payment":
      handlePaymentClick(selectedRow);
      break;
      default:
        break;
    }
    handleMenuClose();
  };

  const handleCloseActivePopup = () => {
    setShowActiveDeActiveWellnessPopup(false);
    setSelectedRow("");
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      sortable: false,
      renderCell: (data) => (
        <CustomTooltip
          title={
            data?.row?.name ? (
              <h6 style={{ color: "white" }}>{data?.row?.name}</h6>
            ) : (
              "N/A"
            )
          }
        >
          <span>
            {data?.row?.name ? charValidate(data?.row?.name, 30) : "N/A"}
          </span>
        </CustomTooltip>
      ),
    },

    {
      field: "mobileNumber",
      headerName: "Mobile Number",
      flex: 1,
      sortable: false,
      renderCell: (data: any) => formatPhoneNumber(data?.row?.countryCode, data?.row?.mobileNumber) ?? "N/A",
    },
    {
      field: "address",
      headerName: "Address",
      flex: 1.5,
      headerAlign:'center',
      align: 'center',
      sortable: false,
      renderCell: (data: any) =>
        data?.row?.address.name +
        "," +
        data?.row?.address.street +
        "," +
        data?.row?.address.city +
        "," +
        data?.row?.address.state +
        "," +
        data?.row?.address.country,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 0.8,
      sortable: false,
      headerAlign:'center',
      renderCell: (data: any) => (
        <span
          dangerouslySetInnerHTML={{
            __html: data?.row?.description ?? "N/A"
          }}
        />
      )
    },
    {
      field: "isActive",
      headerName: "Status",
      flex: 0.8,
      sortable: false,
      headerAlign:'center',
      align: 'center',
      renderCell: (data: any) =>
        typeof data?.row?.isActive !== "undefined" ? (
          data?.row?.isActive ? (
            <div className="d-flex align-items-center">
              <CheckCircleIcon sx={{ fontSize: 15, mr: 1, color: "green" }} />
              <span>Active</span>
            </div>
          ) : (
            <div className="d-flex align-items-center">
              <CheckCircleIcon sx={{ fontSize: 15, mr: 1 }} color="disabled" />
              <span>Inactive</span>
            </div>
          )
        ) : (
          "N/A"
        ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.7,
      sortable: false,
      headerAlign: "center",
      align: "right",
      renderCell: (data) => (
        <Box display="flex" alignItems="center">
          <Tooltip title="More Actions">
            <IconButton
              onClick={(e:any) => handleMenuClick(e, data.row)}
              size="small"
              color="primary"
            >
              <SettingsIcon  />
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={() => handleAction("edit")}>
              <DriveFileRenameOutlineIcon sx={{ mr: 1 }} color="primary" fontSize="small" />
              Edit
            </MenuItem>
             <MenuItem onClick={() => handleAction("payment")}>
              <StoreIcon  sx={{ mr: 1 }} color="primary" fontSize="small" />
              Provider
            </MenuItem>
            <MenuItem onClick={() => handleAction("activeDeactive")}>
              <RestaurantIcon sx={{ mr: 1 }} color="primary" fontSize="small" />
              {selectedRow?.isActive ? "Deactivate" : "Activate"}
            </MenuItem>
            <MenuItem onClick={() => handleAction("delete")}>
              <DeleteSweepIcon sx={{ mr: 1 }} color="error" fontSize="small" />
              Delete
            </MenuItem>
          </Menu>
        </Box>
      ),
    },
  ];

  const handleModalClose = (isSubmit: boolean) => {
    setShowAddEditModal(false);
    setSelectedRow("");
    if (isSubmit) {
      let query = {
        offset: tableOptions?.offset ?? 0,
        limit: tableOptions?.limit ?? 10,
        type: tableOptions?.type ?? "",
        golfType: tableOptions?.golfType ?? "",
        search: debounceSearchValue ?? "",
      };
      getRestaurant(query);
    }
  };

  const handleCloseDelete = () => {
    setShowDeleteConfirmModal(false);
    setSelectedRow("");
  };

  // const handleCloseActiveGolfPopup = () => {
  //   setShowActiveDeActiveGolfPopup(false);
  //   setSelectedRow("");
  // };

  const deleteRestaurant = async () => {
    let response = await deleteWellnessAPI({ id: selectedRow?._id });
    if (response?.status) {
      snackActions.success(response?.message);
      handleCloseDelete();
      let query = {
        offset: tableOptions?.offset ?? 0,
        limit: tableOptions?.limit ?? 10,
        type: tableOptions?.type ?? "",
        golfType: tableOptions?.golfType ?? "",
        search: debounceSearchValue ?? "",
      };
      getRestaurant(query);
    } else {
      snackActions.error(response?.message);
    }
  };

  const handlePagination = (values: { page: number; pageSize: number }) => {
    let page = values?.page;
    let pageSize = values?.pageSize;
    let query = {
      offset:
        pageSize == tableOptions?.limit ? (page === 0 ? 0 : `${page}` + 0) : 0,
      limit: pageSize ?? 10,
      type: tableOptions?.type ?? "",
      golfType: tableOptions?.golfType ?? "",
      search: debounceSearchValue ?? "",
    };
    getRestaurant(query);
  };

  const handleChangeFilters = (fieldName: string, value: string) => {
    setTableOptions((prevState: any) => {
      return {
        ...prevState,
        [fieldName]: value,
      };
    });
  };

  const handleBackClick = () => {
    navigate("/category");
    dispatch(setData("Category"));
  };

  const handleActiveDeActiveRestaurant = async () => {
      let response = await activeInActiveWellnessAPI({
        wellnessId: selectedRow?._id,
        active: !selectedRow?.isActive,
      });
      if (response?.status) {
        snackActions.success(response?.message ?? "Success");
        handleCloseActivePopup();
        let query = {
          offset: tableOptions?.offset ?? 10,
          limit: tableOptions?.limit ?? 10,
          type: tableOptions?.type ?? "",
          golfType: tableOptions?.golfType ?? "",
          search: debounceSearchValue ?? "",
        };
        getRestaurant(query);
      } else {
        snackActions.error(response?.message);
      }
    };

  return (
    <Card className="p-6">
      <>
        <KTCard>
          <MuiTable
            addLabel="Add Wellness"
            handleAddClick={() => {
              setShowAddEditModal(true);
              setSelectedRow('')
            }}
            columns={columns}
            data={wellnessData?.data?.docs ?? []}
            tableRef={tableRef}
            loading={isLoadingWL}
            isRowClickable={true}
            isBackEnable={true}
            handleBackClick={handleBackClick}
            handlePagination={handlePagination}
            tableOptions={tableOptions}
            filters={
              <Box pb={3} display="flex" alignItems="center">
                <Box className="table-search">
                  <label
                    className="form-label fw-bold"
                    style={{ marginLeft: 5 }}
                  >
                    Search By Name
                  </label>
                  <Box className="with-search-ico">
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    placeholder="Search by name"
                    aria-label="Search"
                    style={{
                      backgroundColor: "#E0DEE7",
                      border: "0px solid #ccc",
                      borderRadius: "8px",
                      outline: "none",
                    }}
                    onChange={(e: any) =>
                      handleChangeFilters("search", e?.target?.value)
                    }
                    value={tableOptions?.search}
                  />
                  {tableOptions?.search && (
                        <i
                          className="fa fa-times"
                          aria-hidden="true"
                          style={{
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            cursor: 'pointer',
                          }}
                          onClick={() => handleChangeFilters('search', '')}
                        ></i>
                      )}
                      </Box>
                </Box>
              </Box>
            }
          />
        </KTCard>
        {showAddEditModal && (
          <AddEditWellness
            editData={selectedRow}
            show={showAddEditModal}
            handleClose={handleModalClose}
          />
        )}
        {showDeleteConfirmModal && (
          <ConfirmEventPopup
            showPopup={showDeleteConfirmModal}
            handleClose={handleCloseDelete}
            handleConfirmed={deleteRestaurant}
            loading={isDeleting}
            title={"Delete Wellness"}
            message={"Are you sure, you want to delete this wellness?"}
          />
        )}

        {showActiveDeActiveWellnessPopup && (
          <ConfirmEventPopup
            showPopup={showActiveDeActiveWellnessPopup}
            handleClose={handleCloseActivePopup}
            handleConfirmed={handleActiveDeActiveRestaurant}
            loading={isActiveLoading}
            title={"Active / Deactive Wellness"}
            message={`Are you sure, you want to ${selectedRow?.isActive ? '"Deactivate"' : '"Activate"'
              } this Wellness?`}
          />
        )}
      </>
    </Card>
  );
};

export default WellnessList;
