import { Box, Grid, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { KTCard } from "../../_metronic/helpers";
import CustomTooltip from "../../_metronic/layout/components/common/CustomTooltip";
import { PageTitle } from "../../_metronic/layout/core";
import {
  getAdminCategories,
  getAllGolfClub,
  getAllHealthCareList,
  getAllHotelList,
  getAllWellnessList,
  getCategories,
  getCategoryRestaurant,
  getWaitingList,
  notify,
} from "../../api";
import MuiTable from "../../components/table/MuiTable";
import { charValidate, convertTo24HourFormat } from "../../components/utility";
import {
  formatDate,
  parseDateWithoutTimezone,
} from "../../helpers/commonFunctions";
import useDebounce from "../../hooks/useDebounce";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";
import WaitListInfoDetails from "../../components/waitlist-info/waitlist-info-detail";
import { isAdmin } from "../../hooks/useUserInfo";

const WaitingList: React.FC = () => {
  const [showDetailsPopup, setShowDetailsPopup] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [isrowchecked, setIsRowChecked] = useState(false);
  const [inviteuserid, setInviteUserId] = useState();
  const [checkedIds, setCheckedIds] = useState<string[]>([]);
  const [categoryIdOnChange, setCategoryIdOnChange] = useState(
    process.env.REACT_APP_ID_REST_CATE
  );
  const [itemsData, setItemsData] = useState([]);
  const [selectedItemsValue, setSelectedItemsValue] = useState("");
  const [tableOptions, setTableOptions] = useState({
    offset: 0,
    limit: 10,
    hasNextPage: true,
    hasPrevPage: true,
    totalRowCount: undefined,
    search: "",
    categoryId: categoryIdOnChange || process.env.REACT_APP_ID_REST_CATE,
    itemId: "",
  });
  const tableRef = useRef();
  const navigate = useNavigate();
  const debounceSearchValue: string = useDebounce(tableOptions?.search, 500);

  const {
    mutateAsync: getWaitingdata,
    data: waitingData,
    isLoading: isNotifyData,
  } = useMutation("squezz-me-list", getWaitingList);

  const {
    data: categoryData,
    isLoading: isLoadingCategory,
    error: categoryAPIError,
    mutateAsync: refetchCategories,
  } = useMutation("category", getCategories);

  const {
    data: adminCategoryData,
    isLoading: isLoadingAdminCategory,
    error: adminCategoryAPIError,
    mutateAsync: refetchAdminCategories,
  } = useMutation("admin-category", getAdminCategories);

  const {
    mutateAsync: notifyUser,
    isLoading: isNotifyUser,
    error: notifyError,
  } = useMutation("notify", notify);

  useEffect(() => {
    if (isAdmin) {
      refetchAdminCategories();
    } else {
      refetchCategories();
    }
  }, []);

  const handleSubmit = async (item: any) => {
    let bodyParams: any = {
      categoryId: item.catId,
      notifyIds: [item._id],
    };

    let response: any = null;

    if (item.catId === process.env.REACT_APP_ID_REST_CATE) {
      response = await notifyUser(bodyParams);
    } else if (item.catId === process.env.REACT_APP_ID_HOTEL_CATE) {
      response = await notifyUser(bodyParams);
    } else if (item.catId === process.env.REACT_APP_ID_GOLF_CATE) {
      response = await notifyUser(bodyParams);
    } else if (item.catId === process.env.REACT_APP_ID_HEALTH_CARE_CATE) {
      response = await notifyUser(bodyParams);
    } else if (item.catId === process.env.REACT_APP_ID_SALOON_CATE) {
      response = await notifyUser(bodyParams);
    }

    if (response?.status) {
      snackActions.success(
        response?.message ?? "You have notified to the user"
      );
      setCheckedIds([]);
      getWaiting(tableOptions);
    } else {
      snackActions.error(response?.message ?? "Something went wrong!");
    }
  };

  const handleInviteall = async (categoryIdOnChange: any) => {
    let bodyParams: any = {
      categoryId: categoryIdOnChange || process.env.REACT_APP_ID_REST_CATE,
      notifyIds: checkedIds,
    };

    let response: any = null;
    let categoryId = categoryIdOnChange || process.env.REACT_APP_ID_REST_CATE;

    if (categoryId === process.env.REACT_APP_ID_REST_CATE) {
      response = await notifyUser(bodyParams);
    } else if (categoryId === process.env.REACT_APP_ID_HOTEL_CATE) {
      response = await notifyUser(bodyParams);
    } else if (categoryId === process.env.REACT_APP_ID_GOLF_CATE) {
      response = await notifyUser(bodyParams);
    } else if (categoryId === process.env.REACT_APP_ID_HEALTH_CARE_CATE) {
      response = await notifyUser(bodyParams);
    } else if (categoryId === process.env.REACT_APP_ID_SALOON_CATE) {
      response = await notifyUser(bodyParams);
    }

    // let bodyParams = {
    //   notifyIds: checkedIds,
    // };
    // let response = await notifyUser(bodyParams);

    if (response?.status) {
      snackActions.success(
        response?.message ?? "You have notified to the user"
      );
      setCheckedIds([]);
      getWaiting(tableOptions);
    } else {
      snackActions.error(response?.message ?? "Something went wrong!");
    }
  };

  useEffect(() => {
    getWaiting(tableOptions);
  }, []);

  useEffect(() => {
    getWaiting({
      ...tableOptions,
      search: debounceSearchValue ? debounceSearchValue : "",
    });
  }, [debounceSearchValue, categoryIdOnChange, selectedItemsValue]);

  const getWaiting = async (
    query:
      | {
          offset: number | string;
          limit: number;
          search: string;
        }
      | undefined
  ) => {
    let response = await getWaitingdata(query);
    if (response?.status) {
      setTableOptions((prevState: any) => {
        return {
          ...prevState,
          offset: response?.data?.offset,
          limit: response?.data?.limit,
          hasNextPage: response?.data?.hasNextPage,
          hasPrevPage: response?.data?.hasPrevPage,
          totalRowCount: response?.data?.totalDocs,
        };
      });
    }
  };

  const handleInputChange = (e: any, data: any) => {
    const id = data?.row?._id; // Extract ID from data
    setInviteUserId(id);

    if (e?.target?.checked) {
      // Checkbox is checked, add ID to the array
      setCheckedIds([...checkedIds, id]);
      setIsRowChecked(true); // Set isRowChecked to true
    } else {
      // Checkbox is unchecked, remove ID from the array
      setCheckedIds(checkedIds.filter((checkedId) => checkedId !== id));

      // Check if there are still any checkboxes checked
      setIsRowChecked(
        checkedIds.filter((checkedId) => checkedId !== id).length > 0
      );
    }
    // Log the updated checkedIds array
  };

  const currentDate = new Date();
  const currentTime = new Date()?.toLocaleTimeString();
  const formattedCurrentTime = convertTo24HourFormat(currentTime);

  const getFormmattedDate = (date: any) => {
    var convertedDate = new Date(date);
    var year = convertedDate.getFullYear();

    var month = (1 + convertedDate.getMonth()).toString();
    month = month.length > 1 ? month : "0" + month;

    var day = convertedDate.getDate().toString();
    day = day.length > 1 ? day : "0" + day;

    return month + "/" + day + "/" + year;
  };
  const typeColumns: any = [
    {
      field: " ",
      headerName: " ",
      flex: 0.5,
      sortable: false,
      renderCell: (data: any) => {
        const date = new Date(data.row.bookingDate);
        const time = convertTo24HourFormat(data.row.earliestTime);

        const isDateValid = date.getDate() <= currentDate.getDate();
        const isSameDay = date.getDate() == currentDate.getDate();
        const isTimeValid = time > formattedCurrentTime;
        const isValidDateTime = !isSameDay
          ? isDateValid
            ? false
            : true
          : isTimeValid
          ? true
          : false;

        return (
          <>
            {data?.row.IsNotified || !isValidDateTime ? (
              " "
            ) : (
              <input
                type="checkbox"
                style={{ width: "20px", height: "15px" }}
                onChange={(e) => handleInputChange(e, data)}
              />
            )}
          </>
        );
      },
    },
    {
      field: "name",
      headerName: "User Name",
      flex: 1.2,
      sortable: false,
      renderCell: (data: any) => (
        <Tooltip
          title={
            data?.row?.user ? (
              <h6 style={{ color: "white" }}>{data?.row?.user?.firstName + ' ' + data?.row?.user?.lastName}</h6>
            ) : (
              "N/A"
            )
          }
        >
          <span>
            {data?.row?.user
              ? charValidate(
                  data?.row?.user?.firstName + " " + data?.row?.user?.lastName,
                  15
                )
              : "N/A"}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1.1,
      sortable: false,
      renderCell: (data: any) =>
        (isAdmin ? adminCategoryData?.data?.docs : categoryData?.data?.docs)?.find((s: any) => s?._id == data?.row?.catId)
          ?.name,
    },
    {
      field: "item",
      headerName: "Item Name",
      flex: 1.4,
      sortable: false,
      headerAlign: "center",
      renderCell: (data: any) => (
        <CustomTooltip
          title={
            data?.row?.itemId ? (
              <h6 style={{ color: "white" }}>{data?.row?.itemId?.name}</h6>
            ) : (
              "N/A"
            )
          }
        >
          <span>
            {data?.row?.itemId
              ? charValidate(data?.row?.itemId?.name, 30)
              : "N/A"}
          </span>
        </CustomTooltip>
      ),
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1.3,
      sortable: false,
      headerAlign: "center",
      renderCell: (data: any) => (
        <CustomTooltip
          title={
            data?.row?.itemId ? (
              <h6 style={{ color: "white" }}>{data?.row?.itemId?.type}</h6>
            ) : (
              "N/A"
            )
          }
        >
          <span>
            {data?.row?.itemId
              ? charValidate(data?.row?.itemId?.type, 30)
              : "N/A"}
          </span>
        </CustomTooltip>
      ),
    },
    {
      field: "date",
      headerName: " Date (MM/DD/YY)",
      flex: 1.3,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (data: any) =>
        data?.row?.bookingDate
          ? formatDate(data?.row?.bookingDate, "MM/dd/yy")
          : "N/A",
    },
    {
      field: "earliestTime",
      headerName: "Earliest Time",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (data: any) => convertTo24HourFormat(data?.row?.earliestTime),
    },
    {
      field: "latestTime",
      headerName: "Latest Time",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (data: any) => convertTo24HourFormat(data?.row?.latestTime),
    },
    {
      field: "person",
      headerName: "People",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (data: any) => data?.row?.person ?? "N/A",
    },
    {
      field: "createdFrom",
      headerName: "Created From",
      flex: 1.5,
      sortable: false,
      align: "center",
      headerAlign: 'center',
      renderCell: (data: any) => (data?.row?.typetext ? data?.row?.typetext : 'N/A'),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "right",
      renderCell: (data: any) => {
        const date = new Date(data.row.bookingDate);
        const time = convertTo24HourFormat(data.row.earliestTime);

        const isDateValid = date.getDate() <= currentDate.getDate();
        const isSameDay = date.getDate() == currentDate.getDate();
        const isTimeValid = time > formattedCurrentTime;
        const isValidDateTime = !isSameDay
          ? isDateValid
            ? false
            : true
          : isTimeValid
          ? true
          : false;
        return (
          <Box display="flex" alignItems="center">
            <Tooltip title="Info">
              <InfoIcon
                onClick={() => {
                  setSelectedRow(data.row);
                  setShowDetailsPopup(true);
                }}
                sx={{ fontSize: 22, cursor: "pointer", marginRight: 1.5 }}
                color="primary"
              />
            </Tooltip>
            {!data?.row.IsNotified && !isValidDateTime ? (
              <Button
                type="submit"
                size="sm"
                variant={data?.row.IsNotified ? "secondary" : "primary"}
                disabled={data?.row.IsNotified || !isValidDateTime}
                color={data?.row.IsNotified ? "disabled" : "success"}
                style={{ backgroundColor: "#0B0C17", padding: "9px 11px" }}
                onClick={() => handleSubmit(data.row)}
              >
                Expired
              </Button>
            ) : (
              <Button
                type="submit"
                size="sm"
                variant={data?.row.IsNotified ? "secondary" : "primary"}
                disabled={data?.row.IsNotified || !isValidDateTime}
                color={data?.row.IsNotified ? "disabled" : "success"}
                onClick={() => handleSubmit(data.row)}
              >
                {isNotifyUser ? "Loading" : "Invite"}
              </Button>
            )}
          </Box>
        );
      },
    },
  ];

  const WithoutTypeColumns: any = [
    {
      field: " ",
      headerName: " ",
      flex: 0.5,
      sortable: false,
      renderCell: (data: any) => {
        const date = new Date(data.row.bookingDate);
        const time = convertTo24HourFormat(data.row.earliestTime);

        const isDateValid = date.getDate() <= currentDate.getDate();
        const isSameDay = date.getDate() == currentDate.getDate();
        const isTimeValid = time > formattedCurrentTime;
        const isValidDateTime = !isSameDay
          ? isDateValid
            ? false
            : true
          : isTimeValid
          ? true
          : false;

        return (
          <>
            {data?.row.IsNotified || !isValidDateTime ? (
              " "
            ) : (
              <input
                type="checkbox"
                style={{ width: "20px", height: "15px" }}
                onChange={(e) => handleInputChange(e, data)}
              />
            )}
          </>
        );
      },
    },
    {
      field: "name",
      headerName: "User Name",
      flex: 1.2,
      sortable: false,
      renderCell: (data: any) => (
        <Tooltip
          title={
            data?.row?.user ? (
              <h6 style={{ color: "white" }}>{data?.row?.user?.firstName + ' ' + data?.row?.user?.lastName}</h6>
            ) : (
              "N/A"
            )
          }
        >
          <span>
            {data?.row?.user
              ? charValidate(
                  data?.row?.user?.firstName + " " + data?.row?.user?.lastName,
                  15
                )
              : "N/A"}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1.1,
      sortable: false,
      renderCell: (data: any) =>
        (isAdmin ? adminCategoryData?.data?.docs : categoryData?.data?.docs)?.find((s: any) => s?._id == data?.row?.catId)
          ?.name,
    },
    {
      field: "item",
      headerName: "Item Name",
      flex: 1.4,
      sortable: false,
      headerAlign: "center",
      renderCell: (data: any) => (
        <CustomTooltip
          title={
            data?.row?.itemId ? (
              <h6 style={{ color: "white" }}>{data?.row?.itemId?.name}</h6>
            ) : (
              "N/A"
            )
          }
        >
          <span>
            {data?.row?.itemId
              ? charValidate(data?.row?.itemId?.name, 30)
              : "N/A"}
          </span>
        </CustomTooltip>
      ),
    },
    {
      field: "date",
      headerName: " Date (MM/DD/YY)",
      flex: 1.3,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (data: any) =>
        data?.row?.bookingDate
          ? formatDate(data?.row?.bookingDate, "MM/dd/yy")
          : "N/A",
    },
    {
      field: "earliestTime",
      headerName: "Earliest Time",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (data: any) => convertTo24HourFormat(data?.row?.earliestTime),
    },
    {
      field: "latestTime",
      headerName: "Latest Time",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (data: any) => convertTo24HourFormat(data?.row?.latestTime),
    },
    {
      field: "person",
      headerName: "People",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (data: any) => data?.row?.person ?? "N/A",
    },
    {
      field: "createdFrom",
      headerName: "Created From",
      flex: 1.5,
      sortable: false,
      align: "center",
      headerAlign: 'center',
      renderCell: (data: any) => (data?.row?.typetext ? data?.row?.typetext : 'N/A'),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "right",
      renderCell: (data: any) => {
        const date = new Date(data.row.bookingDate);
        const time = convertTo24HourFormat(data.row.earliestTime);

        const isDateValid = date.getDate() <= currentDate.getDate();
        const isSameDay = date.getDate() == currentDate.getDate();
        const isTimeValid = time > formattedCurrentTime;
        const isValidDateTime = !isSameDay
          ? isDateValid
            ? false
            : true
          : isTimeValid
          ? true
          : false;
        return (
          <Box display="flex" alignItems="center">
            <Tooltip title="Info">
              <InfoIcon
                onClick={() => {
                  setSelectedRow(data.row);
                  setShowDetailsPopup(true);
                }}
                sx={{ fontSize: 22, cursor: "pointer", marginRight: 1.5 }}
                color="primary"
              />
            </Tooltip>
            {!data?.row.IsNotified && !isValidDateTime ? (
              <Button
                type="submit"
                size="sm"
                variant={data?.row.IsNotified ? "secondary" : "primary"}
                disabled={data?.row.IsNotified || !isValidDateTime}
                color={data?.row.IsNotified ? "disabled" : "success"}
                style={{ backgroundColor: "#0B0C17", padding: "9px 11px" }}
                onClick={() => handleSubmit(data.row)}
              >
                Expired
              </Button>
            ) : (
              <Button
                type="submit"
                size="sm"
                variant={data?.row.IsNotified ? "secondary" : "primary"}
                disabled={data?.row.IsNotified || !isValidDateTime}
                color={data?.row.IsNotified ? "disabled" : "success"}
                onClick={() => handleSubmit(data.row)}
              >
                {isNotifyUser ? "Loading" : "Invite"}
              </Button>
            )}
          </Box>
        );
      },
    },
  ];

  const handlePagination = (values: { page: number; pageSize: number }) => {
    let page = values?.page;
    let pageSize = values?.pageSize;
    let query = {
      offset:
        pageSize == tableOptions?.limit ? (page === 0 ? 0 : `${page}` + 0) : 0,
      limit: pageSize ?? 10,
      search: debounceSearchValue ?? "",
      categoryId: categoryIdOnChange || process.env.REACT_APP_ID_REST_CATE,
      itemId: selectedItemsValue ?? "",
    };
    getWaiting(query);
  };

  const handleChangeFilters = (fieldName: string, value: string) => {
    setTableOptions((prevState: any) => {
      return {
        ...prevState,
        [fieldName]: value,
      };
    });
  };

  const handleCategoryChange = (e: any) => {
    setCategoryIdOnChange(e);
  };

  const defaultQuery = {
    offset: 0,
    limit: 1000,
    search: "",
    categoryId: "",
    itemId: "",
  };

  const { mutateAsync: getGolfData, data: golfData } = useMutation(
    "golf-list",
    getAllGolfClub
  );

  const { mutateAsync: getRestaurantAPI } = useMutation(
    "restaurant-list",
    getCategoryRestaurant
  );

  const { mutateAsync: getWellNessAPI } = useMutation(
    "wellness-list",
    getAllWellnessList
  );

  const { mutateAsync: getHealthCareAPI } = useMutation(
    "healthCare-list",
    getAllHealthCareList
  );

  const { mutateAsync: getHotelCareAPI } = useMutation(
    "hotel-list",
    getAllHotelList
  );

  useEffect(() => {
    (async () => {
      const res =
        categoryIdOnChange === process.env.REACT_APP_ID_REST_CATE
          ? await getRestaurantAPI(defaultQuery)
          : categoryIdOnChange === process.env.REACT_APP_ID_HOTEL_CATE
          ? await getHotelCareAPI(defaultQuery)
          : categoryIdOnChange === process.env.REACT_APP_ID_GOLF_CATE
          ? await getGolfData(defaultQuery)
          : categoryIdOnChange === process.env.REACT_APP_ID_HEALTH_CARE_CATE
          ? await getHealthCareAPI(defaultQuery)
          : categoryIdOnChange === process.env.REACT_APP_ID_SALOON_CATE
          ? await getWellNessAPI(defaultQuery)
          : null;
      setItemsData(res?.data?.docs);
    })();
  }, [categoryIdOnChange]);

  const isForHotelAndWellness = (categoryIdOnChange == process.env.REACT_APP_ID_HOTEL_CATE) || (categoryIdOnChange == process.env.REACT_APP_ID_SALOON_CATE)

  return (
    <Card className="p-6">
      <>
        <KTCard>
          <MuiTable
            isAddEnable={false}
            columns={isForHotelAndWellness ? WithoutTypeColumns : typeColumns}
            data={waitingData?.data?.docs ?? []}
            tableRef={tableRef}
            loading={isNotifyData}
            isRowClickable={true}
            handlePagination={handlePagination}
            tableOptions={tableOptions}
            filters={
              <Box pb={3}>
                {/* <Grid container spacing={2} alignItems="center"> */}
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <div className="d-flex justify-content-between align-items-center">
                    <Box display="flex" alignItems="center">
                      <div
                        className="form-group"
                        style={{ minWidth: "145px", marginRight: "20px" }}
                      >
                        <label
                          className="form-label fw-bold"
                          style={{ marginLeft: 5 }}
                        >
                          Category
                        </label>
                        <select
                          onChange={(e) => {
                            handleCategoryChange(e?.target?.value);
                            handleChangeFilters("categoryId", e?.target?.value);
                          }}
                          value={categoryIdOnChange}
                          className="form-select form-select-solid form-select-lg cutom-border"
                        >
                          {(isAdmin ? adminCategoryData?.data?.docs : categoryData?.data?.docs)?.map((category: any) => (
                                <option
                                  value={category?._id}
                                  key={category?._id}
                                >
                                  {category?.name}
                                </option>
                              ))
                            }
                        </select>
                      </div>
                      <div
                        className="form-group"
                        style={{ minWidth: "150px", marginRight: "20px" }}
                      >
                        <label className="form-label fw-bold">Items</label>
                        <select
                          className="form-select form-select-solid form-select-lg cutom-border"
                          value={selectedItemsValue}
                          onChange={(e) => {
                            setSelectedItemsValue(e.target.value);
                            handleChangeFilters("itemId", e?.target?.value);
                          }}
                        >
                          <option value="">Select Item</option>
                          {itemsData && itemsData.length > 0 ? (
                            itemsData.map((item: any) => (
                              <option value={item._id} key={item._id}>
                                {item.name}
                              </option>
                            ))
                          ) : (
                            <option value="" disabled>
                              No data available
                            </option>
                          )}
                        </select>
                      </div>
                      <div
                        style={{ minWidth: "200px" }}
                        className="table-search"
                      >
                        <label
                          className="form-label fw-bold"
                          style={{ marginLeft: 5 }}
                        >
                          Search By Name
                        </label>
                        <Box className="with-search-ico">
                          <input
                            type="text"
                            className="form-control form-control-lg form-control-solid cutom-border"
                            placeholder="Search by name"
                            aria-label="Search"
                            onChange={(e) =>
                              handleChangeFilters("search", e?.target?.value)
                            }
                            value={tableOptions?.search}
                          />
                          {tableOptions?.search && (
                        <i
                          className="fa fa-times"
                          aria-hidden="true"
                          style={{
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            cursor: 'pointer',
                          }}
                          onClick={() => handleChangeFilters('search', '')}
                        ></i>
                      )}
                        </Box>
                      </div>
                    </Box>

                    {checkedIds?.length > 0 && (
                      <div style={{ paddingTop: "38px" }}>
                        <Button
                          type="submit"
                          size="sm"
                          // variant={data?.row.IsNotified ? "secondary" : "primary"}
                          // disabled={data?.row.IsNotified}
                          // color={data?.row.IsNotified ? "disabled" : "success"}
                          onClick={() => handleInviteall(categoryIdOnChange)}
                        >
                          Invite all
                        </Button>
                      </div>
                    )}
                  </div>
                </Grid>
                {/* </Grid> */}
              </Box>
            }
          />
        </KTCard>
        {showDetailsPopup && (
          <WaitListInfoDetails
            details={selectedRow}
            show={showDetailsPopup}
            handleClose={() => setShowDetailsPopup(false)}
          />
        )}
      </>
    </Card>
  );
};

export default WaitingList;
