import { Card, Col, Container, Modal, Row } from "react-bootstrap";
import { createPortal } from "react-dom";
import { KTIcon } from "../../_metronic/helpers";
import { ISquezzMeInfoDetails } from "../../constants/types";
import { charValidate, convertTo24HourFormat } from "../utility";
import { formatDate } from "../../helpers/commonFunctions";
import { Chip, Tooltip } from "@mui/material";
import { Padding } from "@mui/icons-material";
import { isSuperAdmin } from "../../hooks/useUserInfo";
import './SquezzMeInfoDetails.css'

type Props = {
  show: boolean;
  handleClose: (isSubmit: boolean) => void;
  // details: ISquezzMeInfoDetails;
  details: any
};

const modalsRoot = document.getElementById("root-modals") || document.body;

const SquezzMeInfoDetails = ({ show, handleClose, details }: Props) => {

  const golfDetails = details?.catId == "65c61866ea562b9cfd579468"
  const restaurantDetails = details?.catId == "65c608806782899b0698f069"
  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-600px"
      show={show}
      onHide={() => handleClose(false)}
      backdrop="static"
    >
      <div className="modal-header">
        <h2>Request Details</h2>
        {/* begin::Close */}
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={() => handleClose(false)}
        >
          <KTIcon className="fs-1" iconName="cross" />
        </div>
        {/* end::Close */}
      </div>

      <div className="p-3" style={{ maxHeight: '80vh', overflowY: 'auto' }}>
        <Card style={{ width: "100%", boxShadow: "none" , paddingBottom : 0 }}>
          <Card.Body>
            <Card.Title className="pb-4">User Details</Card.Title>
            <Container>
              <Row>
                <Col xs={6} style={{ paddingLeft: 0 }}>
                  <Card.Subtitle className="text-muted">
                    Full Name:
                  </Card.Subtitle>
                   <Card.Text>
                    <Tooltip
                      title={details?.userId?.firstName && details?.userId?.lastName ? <h6 style={{ color: "white" }}>{details?.userId?.firstName + " " + details?.userId?.lastName}</h6> : "N/A"}
                      arrow
                    >
                      <span style={{ cursor: 'pointer' }}>
                        {details?.userId?.firstName || details?.userId?.lastName
                          ? charValidate(
                            details?.userId?.firstName +
                            " " +
                            details?.userId?.lastName,
                            20,
                          )
                          : "N/A"}
                      </span>
                    </Tooltip>
                    </Card.Text>
                </Col>
                {((details?.status != 0 || details?.status != 9) && !details?.IsAutoApprove) && (
                <Col xs={6}>
                  <Card.Subtitle className="text-muted">Approved By:</Card.Subtitle>
                  <Card.Text>
                      {details?.approvedBy
                        ?  details?.approvedBy?.firstName +
                        " " +
                        details?.approvedBy?.lastName
                        : "N/A"}
                    </Card.Text>
                </Col>
                )}
              </Row>
            </Container>
          </Card.Body>
        </Card>
        <hr className="m-0"/>
        <Card style={{ width: "100%", boxShadow: "none" , paddingTop: 0 }}>
          <Card.Body>
            <Card.Title className="pb-4">Squeez Details</Card.Title>
            <Container>
              <Row>
                <Col xs={6} style={{ paddingLeft: 0 }}>
                  <Card.Subtitle className="text-muted">Category:</Card.Subtitle>
                  <Card.Text>
                    {details?.catId === process.env.REACT_APP_ID_REST_CATE
                      ? 'Restaurant'
                      : details?.catId === process.env.REACT_APP_ID_HOTEL_CATE
                        ? 'Hotel'
                        : details?.catId === process.env.REACT_APP_ID_GOLF_CATE
                          ? 'Golf'
                          : details?.catId === process.env.REACT_APP_ID_HEALTH_CARE_CATE
                            ? 'Healthcare'
                            : details?.catId === process.env.REACT_APP_ID_SALOON_CATE
                              ? 'Wellness'
                              : 'NA'
                    }
                  </Card.Text>

                </Col>
                <Col xs={6}>
                  <Card.Subtitle className="text-muted">
                    Item Name:
                  </Card.Subtitle>
                  <Card.Text>
                     {details?.itemId?.name}
                    </Card.Text>
                </Col>
              </Row>

              <Row className="pt-2">
                <Col xs={6} style={{ paddingLeft: 0 , paddingTop : 10}}>
                  <Card.Subtitle className="text-muted">
                    Desired Date:
                  </Card.Subtitle>
                  <Card.Text>
                     {formatDate(details?.slotDate, "MM/dd/yy")}
                    </Card.Text>
                </Col>
               
                <Col xs={6} style={{paddingTop : 10}}>
                  <Card.Subtitle className="text-muted">
                    People:
                  </Card.Subtitle>
                  <Card.Text>
                      {details?.person}
                    </Card.Text>
                </Col>
              </Row>
              <Row className="pt-2">
              <Col xs={6} style={{ paddingLeft: 0, paddingTop : 10}}>
                  <Card.Subtitle className="text-muted">
                  Earliest Time:
                  </Card.Subtitle>
                  <Card.Text>
                
                  {details?.startTime
                      ? convertTo24HourFormat(details?.startTime)
                      : "N/A"}
                    </Card.Text>
                </Col>
                <Col xs={6} style={{paddingTop : 10}}>
                  <Card.Subtitle className="text-muted">
                   Latest Time:
                  </Card.Subtitle>
                  <Card.Text>
                  {details?.endTime
                      ? convertTo24HourFormat(details?.endTime)
                      : "N/A"}
                    </Card.Text>
                </Col>
              </Row>

              <Row className="pt-2">
              <Col xs={6} style={{ paddingLeft: 0, paddingTop : 10}}>
                  <Card.Subtitle className="text-muted">
                  Squeez Fees:
                  </Card.Subtitle>
                  <Card.Text>
                  ${details?.requestPrice}
                    </Card.Text>
                </Col>
                <Col xs={6} style={{ paddingTop : 10}}>
                  <Card.Subtitle className="text-muted">
                   Total Squeez request:
                  </Card.Subtitle>
                  <Card.Text>
                  ${details?.requestPrice * parseInt( details?.person)}
                    </Card.Text>
                </Col>  
              </Row>

              <Row>
              <Col  xs={6} style={{ paddingLeft: 0,paddingTop: 15 }}>
                  <Card.Subtitle className="text-muted">
                  Annual Squeez Opportunity:
                  </Card.Subtitle>
                  <Card.Text>
                  ${details?.requestPrice * parseInt( details?.person) *365}
                    </Card.Text>
                </Col>  
                <Col xs={6} style={{ paddingTop : 15}}>
                <Card.Subtitle className="text-muted">
                    Description:
                  </Card.Subtitle>
                  <Card.Text>
                      {details?.description
                        ? details?.description
                        : "N/A"}
                    </Card.Text>
                </Col>
               
               
              </Row>
            </Container>
          </Card.Body>
        </Card>
        {details?.status != 0 && (
          <>  
          <hr className="m-0"/>
        <Card style={{ width: "100%", boxShadow: "none" , paddingTop: 0 }}>
          <Card.Body>
            <Card.Title className="pb-2">Admin Adjusted Details</Card.Title>
            <Container>
            <Row>
              <Col  xs={6} style={{ paddingLeft: 0,paddingTop: 10 }}>
                  <Card.Subtitle className="text-muted">
                 Admin change date:
                  </Card.Subtitle>
                  <Card.Text>
                  {details?.adminDesiredDate ? formatDate(details?.adminDesiredDate, "MM/dd/yy") : formatDate(details?.slotDate, "MM/dd/yy")}
                    </Card.Text>
                </Col>  
                <Col xs={6} style={{paddingTop : 10}}>
                <Card.Subtitle className="text-muted">
                    Admin Change time:
                  </Card.Subtitle>
                  <Card.Text>
                     {details?.adminDesiredTime ? convertTo24HourFormat(details?.adminDesiredTime) : convertTo24HourFormat(details?.startTime)}
                    </Card.Text>
                </Col>
               
               
              </Row>
              <Row>
              <Col  xs={6} style={{ paddingLeft: 0,paddingTop: 10 }}>
                  <Card.Subtitle className="text-muted">
                Minimum Spend:
                  </Card.Subtitle>
                  <Card.Text>
                  {details?.minimumSpend ? `$${details?.minimumSpend}` : "N/A"}
                    </Card.Text>
                </Col>  
               
              <Col  xs={6} style={{ paddingTop: 10 }}>
                  <Card.Subtitle className="text-muted">
                  Return Time:
                  </Card.Subtitle>
                  <Card.Text>
                  {details?.returnTime ? convertTo24HourFormat(details?.returnTime) : convertTo24HourFormat(details?.endTime)}
                    </Card.Text>
                </Col>  
               
              </Row>

              <Row>
              <Col xs={6} style={{ paddingLeft: 0, paddingTop : 10}}>
                  <Card.Subtitle className="text-muted">
                  Approved squeez per person:
                  </Card.Subtitle>
                  <Card.Text>
                        {(details?.status != 9 && !details?.IsAutoApprove) ? (
                          details?.finalPrice ? `$${details?.finalPrice}` : "N/A"
                        ) : (
                          details?.requestPrice ? `$${details?.requestPrice}` : "N/A"
                        )}
                    </Card.Text>
                </Col>
                {details?.reasonText && 
                   <Col  xs={6} style={{ paddingTop: 10 }}>
                    <Card.Subtitle className="text-muted">
                      {details.status == 3 ?  'Reason' : 'Description'} 
                  </Card.Subtitle>
                  <Card.Text>
                      {details.reasonText}
                    </Card.Text>
                   </Col>
                }
              </Row>
            </Container>
          </Card.Body>
        </Card>
        </>
        )}
        {details?.status == 6 && (
          <>
          <hr className="m-0"/>
        <Card style={{ width: "100%", boxShadow: "none" , paddingTop: 0 }}>
          <Card.Body>
            <Card.Title className="pb-2">Payment Information</Card.Title>
            <Container>
            <Row>
              <Col  xs={6} style={{ paddingLeft: 0,paddingTop: 10 }}>
                  <Card.Subtitle className="text-muted">
                 Booking ID:
                  </Card.Subtitle>
                  <Card.Text>
                  {details?.booking?.id ?? "N/A"}
                    </Card.Text>
                </Col>  
                <Col  xs={6} style={{ paddingTop: 10 }}>
                  <Card.Subtitle className="text-muted">
                  Payment Mode:
                  </Card.Subtitle>
                  <Card.Text>
                  {details?.booking?.paymentMode ?? "N/A"}
                    </Card.Text>
                </Col>  
              </Row>
              <Row>
              <Col xs={6} style={{ paddingLeft: 0,paddingTop : 10}}>
                <Card.Subtitle className="text-muted">
                   Booking Date:
                  </Card.Subtitle>
                  <Card.Text>
                     {details?.booking?.bookingDate ? `${formatDate(details?.booking?.bookingDate, "MM/dd/yy")}` : "N/A"}
                    </Card.Text>
                </Col>
              <Col  xs={6} style={{paddingTop: 10 }}>
                  <Card.Subtitle className="text-muted">
                Booking Time:
                  </Card.Subtitle>
                  <Card.Text>
                  {convertTo24HourFormat(details?.booking?.bookingTime) ??"N/A"}
                    </Card.Text>
                </Col>  
              </Row>

              <Row>
              <Col xs={6} style={{ paddingLeft: 0, paddingTop : 10}}>
                  <Card.Subtitle className="text-muted">
                 People:
                  </Card.Subtitle>
                  <Card.Text>
                  {details?.booking?.person ?? "N/A"}
                    </Card.Text>
                </Col>
                <Col xs={6} style={{ paddingTop : 10}}>
                  <Card.Subtitle className="text-muted">
                 Minimum Spend:
                  </Card.Subtitle>
                  <Card.Text>
                  {details?.booking?.minimumSpend ? `$${details?.booking?.minimumSpend}` : "N/A"}
                    </Card.Text>
                </Col>
              </Row>

              <Row>
              <Col xs={6} style={{ paddingLeft: 0, paddingTop : 10}}>
                  <Card.Subtitle className="text-muted">
                 Tip({details?.booking?.tipFeesInPercentage}%):
                  </Card.Subtitle>
                  <Card.Text>
                  {details?.booking?.tipFees ? `$${details?.booking?.tipFees}` : "N/A"}
                    </Card.Text>
                </Col>
                <Col xs={6} style={{ paddingTop : 10}}>
                  <Card.Subtitle className="text-muted">
                  Squeez Fees({details?.person} * {details?.finalPrice ? `$${details?.finalPrice}` : `$${details?.requestPrice}`})
                  </Card.Subtitle>
                  <Card.Text>
                  {details?.booking?.totalSqueezFees ? `$${details?.booking?.totalSqueezFees}` : "N/A"}
                    </Card.Text>
                </Col>
              </Row>

              <Row>
              <Col xs={6} style={{ paddingLeft: 0, paddingTop : 10}}>
                  <Card.Subtitle className="text-muted">
                 Platform Fee({details?.booking?.platformFessPerc}%):
                  </Card.Subtitle>
                  <Card.Text>
                  {details?.booking?.platFormFee ? `$${parseInt(details?.booking?.platFormFee)?.toFixed(2)}` : "N/A"}
                    </Card.Text>
                </Col>
                <Col xs={6} style={{ paddingTop : 10}}>
                  <Card.Subtitle className="text-muted">
                 Taxes({details?.itemId?.tax}%):
                  </Card.Subtitle>
                  <Card.Text>
                  {details?.booking?.taxeAmount 
      ? `$${parseFloat(details?.booking?.taxeAmount)?.toFixed(2)}`
      : "N/A"}
                    </Card.Text>
                </Col>
              </Row>
              <Row>
              <Col xs={6} style={{ paddingLeft: 0, paddingTop : 10}}>
                  <Card.Subtitle className="text-muted">
                 Total:
                  </Card.Subtitle>
                  <Card.Text>
                  {details?.booking?.totalAmount ? `$${details?.booking?.totalAmount}` : "N/A"}
                    </Card.Text>
                </Col>
              </Row>
            </Container>
          </Card.Body>
        </Card>
        </>
        )}
        {details?.IsSqueezBySuperUser && isSuperAdmin && 
        ( 
          <>
        <hr className="m-0"/>
        <Card style={{ width: "100%", boxShadow: "none" , paddingTop: 0 }}>
          <Card.Body>
            <Card.Title className="pb-4">Guest Information</Card.Title>
            <Container>
            <Row>
              <Col  xs={6} style={{ paddingLeft: 0,paddingTop: 10 }}>
                  <Card.Subtitle className="text-muted">
                 Guest Name:
                  </Card.Subtitle>
                  <Card.Text>
                  {details?.guestName ? details?.guestName : "N/A"}
                    </Card.Text>
                </Col>  
                <Col xs={6} style={{paddingTop : 10}}>
                <Card.Subtitle className="text-muted">
                Guest Mobile Number:
                  </Card.Subtitle>
                  <Card.Text>
                     {details?.guestMobileNumber ? `${details?.guestCountryCode} ${details?.guestMobileNumber}` : "N/A"}
                    </Card.Text>
                </Col>
               
               
              </Row>
              <Row>
              <Col  xs={6} style={{ paddingLeft: 0,paddingTop: 15 }}>
                  <Card.Subtitle className="text-muted">
                  Guest Email:
                  </Card.Subtitle>
                  <Card.Text>
                 {details?.guestEmail ? details?.guestEmail : "N/A"}
                    </Card.Text>
                </Col>   
               
              </Row>
            </Container>
          </Card.Body>
        </Card>
        </>
        )}
        {(restaurantDetails || golfDetails) && (
          <>
        <hr className="m-0"/>
        <Card style={{ width: "100%", boxShadow: "none" , paddingTop: 0 }}>
          <Card.Body>
            <Card.Title className="pb-4">User Preferences</Card.Title>
            {restaurantDetails && (
                              <>
                          <Row  style={{ paddingLeft:0 , paddingTop : 10}}>
                            <Col xs={6}>
                            <div className="user-preference-info-card">
                              <label className="mb-6 fw-bold" style={{fontSize : '14px', paddingLeft : 0}}> Lunch Time</label>
                              <div className="d-flex justify-content-between">
                                <div>
                              <Card.Subtitle className="text-muted">
                               Earliest Time
                              </Card.Subtitle>
                              <Card.Text>
                                 {details?.preference?.lunchEarliestTime ? details?.preference?.lunchEarliestTime : "N/A"}
                                </Card.Text>
                                </div>
                                <div>
                              <Card.Subtitle className="text-muted">
                               Latest Time
                              </Card.Subtitle>
                              <Card.Text>
                              {details?.preference?.lunchLatestTime ? details?.preference?.lunchLatestTime : "N/A"}
                                </Card.Text>
                                </div>
                                </div>
                                </div>
                            </Col>
                            <Col xs={6}>
                            <div className="user-preference-info-card">
                        <label className='mb-6 fw-bold' style={{fontSize : '14px', paddingLeft : 0}}>Dinner Time</label>
                        <div className="d-flex justify-content-between">
                          <div>
                              <Card.Subtitle className="text-muted">
                               Earliest Time
                              </Card.Subtitle>
                              <Card.Text>
                                 {details?.preference?.dinnerEarliestTime ? details?.preference?.dinnerEarliestTime : "N/A"}
                                </Card.Text>
                                </div>
                                <div>
                              <Card.Subtitle className="text-muted">
                               Latest Time
                              </Card.Subtitle>
                              <Card.Text>
                              {details?.preference?.dinnerLatestTime ? details?.preference?.dinnerLatestTime : "N/A"}
                                </Card.Text>
                                </div>
                                </div>
                            </div>
                            </Col>
                        </Row>
            
                        <Row  style={{ paddingLeft: 0 , paddingTop : 15}}>
                            <Col xs={6}>
                            <div className="user-preference-info-card">
                              <Card.Subtitle className="text-muted">
                                {/* Spend per Outing(in $) */}
                                Spend per Outing
                              </Card.Subtitle>
                              <Card.Text>
                              {details?.preference?.spendPerOuting ? `$${details?.preference?.spendPerOuting}` : 'N/A'}
                                </Card.Text>
                                </div>
                            </Col>
                            <Col xs={6}>
                            <div className="user-preference-info-card">
                              <Card.Subtitle className="text-muted">
                                Average Tip
                              </Card.Subtitle>
                              <Card.Text>
                              {details?.preference?.averageTip ? `$${details?.preference?.averageTip}` : 'N/A'}
                                </Card.Text>
                                </div>
                            </Col>
                            
                        </Row>
                          <Row style={{ paddingLeft: 0 , paddingTop : 15}}>
                          <Col xs={6} >
                              <Card.Subtitle className="text-muted">
                              Dishes:
                              </Card.Subtitle>
                              <Card.Text>
                                {details?.preference?.dishes?.length ? (
                                  details?.preference?.dishes.map((title: any, index: number) => (
                                    <Chip key={index} label={title?.name} style={{ marginTop: '0.6rem', marginRight: '0.6rem' }} variant="outlined"/>
                                  ))
                                ) : (
                                  <Card.Text>N/A</Card.Text>
                                )}
                              </Card.Text>
                         </Col>
                         <Col xs={6}>
                              <Card.Subtitle className="text-muted">
                              Dinning:
                              </Card.Subtitle>
                              <Card.Text>
                                {details?.preference?.dinning?.length ? (
                                  details?.preference?.dinning.map((title: any, index: number) => (
                                    <Chip key={index} label={title?.name} style={{ marginTop: '0.6rem', marginRight: '0.6rem' }} variant="outlined"/>
                                  ))
                                ) : (
                                  <Card.Text>N/A</Card.Text>
                                )}
                              </Card.Text>
                         </Col>
                          </Row>
            
                          <Row style={{ paddingLeft: 0 , paddingTop : 15}}>
                          <Col xs={6}>
                              <Card.Subtitle className="text-muted">
                              Wines:
                              </Card.Subtitle>
                              <Card.Text>
                                {details?.preference?.wines?.length ? (
                                  details?.preference?.wines.map((title: any, index: number) => (
                                    <Chip key={index} label={title?.name} style={{ marginTop: '0.6rem', marginRight: '0.6rem' }} variant="outlined"/>
                                  ))
                                ) : (
                                  <Card.Text>N/A</Card.Text>
                                )}
                              </Card.Text>
                            </Col>
                          <Col xs={6}>
                              <Card.Subtitle className="text-muted">
                              Music:
                              </Card.Subtitle>
                              <Card.Text>
                                {details?.preference?.music?.length ? (
                                  details?.preference?.music.map((title: any, index: number) => (
                                    <Chip key={index} label={title?.name} style={{ marginTop: '0.6rem', marginRight: '0.6rem' }} variant="outlined"/>
                                  ))
                                ) : (
                                  <Card.Text>N/A</Card.Text>
                                )}
                              </Card.Text>
                            </Col>       
                          </Row>
                          </>
                            )}
                            {golfDetails && (
                                            <>
                                          <label className="mb-4 fw-bold" style={{fontSize : '16px', paddingLeft : 0}}> Golfer Profiles</label>
                                          <Row style={{ paddingLeft:0 , paddingTop : 10}}>
                                          <Col xs={6}>
                                          <div className="user-preference-info-card">
                                              <Card.Subtitle className="text-muted">
                                               GHIN/Handicap score
                                              </Card.Subtitle>
                                              <Card.Text>
                                              {details?.preference?.handicap ? details?.preference?.handicap : "N/A"}
                                                </Card.Text>
                                                </div>
                                            </Col>
                                            <Col xs={6}>
                                            <div className="user-preference-info-card">
                                              <Card.Subtitle className="text-muted">
                                               Targeted Cost/Round score
                                              </Card.Subtitle>
                                              <Card.Text>
                                              {details?.preference?.averageScore ? details?.preference?.averageScore : "N/A"}
                                                </Card.Text>
                                                </div>
                                            </Col>
                                          </Row>
                            
                                          <Row style={{ paddingLeft:0 , paddingTop : 10}}>
                                          <Col xs={6}>
                                          <div className="user-preference-info-card">
                                              <Card.Subtitle className="text-muted">
                                               Skill Level
                                              </Card.Subtitle>
                                              <Card.Text>
                                              {details?.preference?.skillLevel?.name ? details?.preference?.skillLevel?.name : "N/A"}
                                                </Card.Text>
                                                </div>
                                            </Col>
                                            <Col xs={6}>
                                            <div className="user-preference-info-card">
                                              <Card.Subtitle className="text-muted">
                                               Golf Frequency
                                              </Card.Subtitle>
                                              <Card.Text>
                                              {details?.preference?.golfFrequency?.name ? details?.preference?.golfFrequency?.name : "N/A"}
                                                </Card.Text>
                                                </div>
                                            </Col>
                                          </Row>
                            
                                          <Row style={{ paddingLeft:0 , paddingTop : 10}}>
                                          <Col xs={6}>
                                          <div className="user-preference-info-card">
                                              <Card.Subtitle className="text-muted">
                                               Time Range
                                              </Card.Subtitle>
                                              <Card.Text>
                                              {details?.preference?.timeRange?.name ?? "N/A"}
                                                </Card.Text>
                                                </div>
                                            </Col>
                                            <Col xs={6}>
                                            <div className="user-preference-info-card">
                                              <Card.Subtitle className="text-muted">
                                               Hobbies
                                              </Card.Subtitle>
                                              <Card.Text>
                                              {details?.preference?.hobbies ? details?.preference?.hobbies : "N/A"}
                                                </Card.Text>
                                                </div>
                                            </Col>
                                          </Row>
                            
                                          <label className="mt-6 mb-4 fw-bold" style={{fontSize : '16px', paddingLeft : 0}}> Preferences</label>
                            
                                          <Row style={{ paddingLeft:0 , paddingTop : 10}}>
                                            <Col xs={6}>
                                              <Card.Subtitle className="text-muted">
                                                Golf Courses
                                              </Card.Subtitle>
                                              {details?.preference?.golfCourse?.length ? (
                                                details?.preference.golfCourse.map((item: any, index: any) => (
                                                  <Chip key={index} label={item?.name} style={{ marginTop: '0.6rem', marginRight: '0.6rem' }} variant="outlined"/>
                                                ))
                                              ) : 'N/A'}
                                            </Col>
                                            <Col xs={6}>
                                              <Card.Subtitle className="text-muted">
                                               Music Category
                                              </Card.Subtitle>
                                              {details?.preference?.music?.length ? (
                                                details?.preference.music.map((item: any, index: any) => (
                                                  <Chip key={index} label={item?.name} style={{ marginTop: '0.6rem', marginRight: '0.6rem' }} variant="outlined"/>
                                                ))
                                              ) : "N/A"}
                                            </Col>
                                          </Row>
                            
                                          <Row style={{ paddingLeft:0 , paddingTop : 10}}>
                                          <Col xs={6}>
                                              <Card.Subtitle className="text-muted">
                                               Typical Courses
                                              </Card.Subtitle>
                                              {details?.preference?.courses?.length ? (
                                                details?.preference.courses.map((item: any, index: any) => (
                                                  <Chip key={index} label={item?.name} style={{ marginTop: '0.6rem', marginRight: '0.6rem' }} variant="outlined"/>
                                                ))
                                              ) : "N/A"}
                                            </Col>
                                            <Col xs={6}>
                                              <Card.Subtitle className="text-muted">
                                               Primary Purpose
                                              </Card.Subtitle>
                                              {details?.preference?.primaryPurpose?.length ? (
                                                details?.preference.primaryPurpose.map((item: any, index: any) => (
                                                  <Chip key={index} label={item?.name} style={{ marginTop: '0.6rem', marginRight: '0.6rem' }} variant="outlined"/>
                                                ))
                                              ) : "N/A"}
                                            </Col>
                                          </Row>
                            
                                          <Row style={{ paddingLeft:0 , paddingTop : 10}}>
                                          <Col xs={6}>
                                              <Card.Subtitle className="text-muted">
                                              Preferences
                                              </Card.Subtitle>
                                              {details?.preference?.tolerance?.length ? (
                                                details?.preference.tolerance.map((item: any, index: any) => (
                                                  <Chip key={index} label={item?.name} style={{ marginTop: '0.6rem', marginRight: '0.6rem' }} variant="outlined"/>
                                                ))
                                              ) : "N/A"}
                                            </Col>
                                            <Col xs={6}>
                                              <Card.Subtitle className="text-muted">
                                               Dislikes
                                              </Card.Subtitle>
                                              {details?.preference?.boxes?.length ? (
                                                details?.preference.boxes.map((item: any, index: any) => (
                                                  <Chip key={index} label={item?.name} style={{ marginTop: '0.6rem', marginRight: '0.6rem' }} variant="outlined"/>
                                                ))
                                              ) : "N/A"}
                                            </Col>
                                          </Row>
                            
                                          <Row style={{ paddingLeft:0 , paddingTop : 10}}>
                                          <Col xs={6}>
                                              <Card.Subtitle className="text-muted">
                                               Payment style
                                              </Card.Subtitle>
                                              {details?.preference?.payment?.length ? (
                                                details?.preference.payment.map((item: any, index: any) => (
                                                  <Chip key={index} label={item?.name} style={{ marginTop: '0.6rem', marginRight: '0.6rem' }} variant="outlined"/>
                                                ))
                                              ) : "N/A"}
                                            </Col>
                                          </Row>
                            
                                          <Card.Subtitle className="text-muted mt-4">
                                               Polo
                                              </Card.Subtitle>
                                  <Row style={{ paddingLeft: 0, paddingTop: 10 }}>
                                  {details?.preference?.apparel?.polo?.length ? (
                                details?.preference?.apparel?.polo.map((item: any, index: any) => (
                                <Col xs={4} key={index}>
                            <div className="card p-2 shadow-sm rounded border mt-2">
                                {/* Product Info */}
                                <div className="d-flex justify-content-between mb-2">
                                  <label className="text-muted">Name:</label>
                                  <span>{item?.name ?? "N/A"}</span>
                                </div>
                            
                                <div className="d-flex justify-content-between mb-2">
                                  <label className="text-muted">Brand:</label>
                                  <span>{item?.brand?.value ?? "N/A"}</span>
                                </div>
                            
                                <div className="d-flex justify-content-between mb-2">
                                  <label className="text-muted">Size:</label>
                                  <span>{item?.size?.value ?? "N/A"}</span>
                                </div>
                            
                                <div className="d-flex justify-content-between mb-2">
                                  <label className="text-muted">Price:</label>
                                  <span>{item?.price ?? "N/A"}</span>
                                </div>
                              </div>
                                    </Col>
                                  ))
                                ) : (
                                  <span>N/A</span>
                                )}
                                </Row>
                            
                                <Card.Subtitle className="text-muted mt-4">Hats</Card.Subtitle>
                                  <Row style={{ paddingLeft: 0, paddingTop: 10 }}>
                                  {details?.preference?.apparel?.hats?.length ? (
                                details?.preference?.apparel?.hats.map((item: any, index: any) => (
                                <Col xs={4} key={index}>
                            <div className="card p-2 shadow-sm rounded border mt-2">
                                <div className="d-flex justify-content-between mb-2">
                                  <label className="text-muted">Name:</label>
                                  <span>{item?.name ?? "N/A"}</span>
                                </div>
                            
                                <div className="d-flex justify-content-between mb-2">
                                  <label className="text-muted">Brand:</label>
                                  <span>{item?.brand?.value ?? "N/A"}</span>
                                </div>
                            
                                <div className="d-flex justify-content-between mb-2">
                                  <label className="text-muted">Size:</label>
                                  <span>{item?.size?.value ?? "N/A"}</span>
                                </div>
                            
                                <div className="d-flex justify-content-between mb-2">
                                  <label className="text-muted">Price:</label>
                                  <span>{item?.price ?? "N/A"}</span>
                                </div>
                              </div>
                                    </Col>
                                  ))
                                ) : (
                                  <span>N/A</span>
                                )}
                                </Row>
                            
                                <Card.Subtitle className="text-muted mt-4">Jacket/Sweaters</Card.Subtitle>
                                  <Row style={{ paddingLeft: 0, paddingTop: 10 }}>
                                  {details?.preference?.apparel?.cloth?.length ? (
                                details?.preference?.apparel?.cloth.map((item: any, index: any) => (
                                <Col xs={4} key={index}>
                            <div className="card p-2 shadow-sm rounded border mt-2">
                                {/* Product Info */}
                                <div className="d-flex justify-content-between mb-2">
                                  <label className="text-muted">Name:</label>
                                  <span>{item?.name ?? "N/A"}</span>
                                </div>
                            
                                <div className="d-flex justify-content-between mb-2">
                                  <label className="text-muted">Brand:</label>
                                  <span>{item?.brand?.value ?? "N/A"}</span>
                                </div>
                            
                                <div className="d-flex justify-content-between mb-2">
                                  <label className="text-muted">Size:</label>
                                  <span>{item?.size?.value ?? "N/A"}</span>
                                </div>
                            
                                <div className="d-flex justify-content-between mb-2">
                                  <label className="text-muted">Price:</label>
                                  <span>{item?.price ?? "N/A"}</span>
                                </div>
                              </div>
                                    </Col>
                                  ))
                                ) : (
                                  <span>N/A</span>
                                )}
                                </Row>
                            
                                <Card.Subtitle className="text-muted mt-4">Shorts/Pants</Card.Subtitle>
                                  <Row style={{ paddingLeft: 0, paddingTop: 10 }}>
                                  {details?.preference?.apparel?.shorts?.length ? (
                                details?.preference?.apparel?.shorts.map((item: any, index: any) => (
                                <Col xs={4} key={index}>
                            <div className="card p-2 shadow-sm rounded border mt-2">
                                {/* Product Info */}
                                <div className="d-flex justify-content-between mb-2">
                                  <label className="text-muted">Name:</label>
                                  <span>{item?.name ?? "N/A"}</span>
                                </div>
                            
                                <div className="d-flex justify-content-between mb-2">
                                  <label className="text-muted">Brand:</label>
                                  <span>{item?.brand?.value ?? "N/A"}</span>
                                </div>
                            
                                <div className="d-flex justify-content-between mb-2">
                                  <label className="text-muted">Size:</label>
                                  <span>{item?.size?.value ?? "N/A"}</span>
                                </div>
                            
                                <div className="d-flex justify-content-between mb-2">
                                  <label className="text-muted">Price:</label>
                                  <span>{item?.price ?? "N/A"}</span>
                                </div>
                              </div>
                                    </Col>
                                  ))
                                ) : (
                                  <span>N/A</span>
                                )}
                                </Row>
                            
                                <Card.Subtitle className="text-muted mt-4">Shoes</Card.Subtitle>
                                  <Row style={{ paddingLeft: 0, paddingTop: 10 }}>
                                  {details?.preference?.apparel?.shoes?.length ? (
                                details?.preference?.apparel?.shoes.map((item: any, index: any) => (
                                <Col xs={4} key={index}>
                            <div className="card p-2 shadow-sm rounded border mt-2">
                                {/* Product Info */}
                                <div className="d-flex justify-content-between mb-2">
                                  <label className="text-muted">Name:</label>
                                  <span>{item?.name ?? "N/A"}</span>
                                </div>
                            
                                <div className="d-flex justify-content-between mb-2">
                                  <label className="text-muted">Brand:</label>
                                  <span>{item?.brand?.value ?? "N/A"}</span>
                                </div>
                            
                                <div className="d-flex justify-content-between mb-2">
                                  <label className="text-muted">Size:</label>
                                  <span>{item?.size?.value ?? "N/A"}</span>
                                </div>
                            
                                <div className="d-flex justify-content-between mb-2">
                                  <label className="text-muted">Price:</label>
                                  <span>{item?.price ?? "N/A"}</span>
                                </div>
                              </div>
                                    </Col>
                                  ))
                                ) : (
                                  <span>N/A</span>
                                )}
                                </Row>
                            
                                <Card.Subtitle className="text-muted mt-4">Golf Clubs</Card.Subtitle>
                                  <Row style={{ paddingLeft: 0, paddingTop: 10 }}>
                                  {details?.preference?.apparel?.golfClub?.length ? (
                                details?.preference?.apparel?.golfClub.map((item: any, index: any) => (
                                <Col xs={4} key={index}>
                            <div className="card p-2 shadow-sm rounded border mt-2">
                                {/* Product Info */}
                                <div className="d-flex justify-content-between mb-2">
                                  <label className="text-muted">Name:</label>
                                  <span>{item?.name ?? "N/A"}</span>
                                </div>
                            
                                <div className="d-flex justify-content-between mb-2">
                                  <label className="text-muted">Brand:</label>
                                  <span>{item?.brand?.name ?? "N/A"}</span>
                                </div>
                            
                                <div className="d-flex justify-content-between mb-2">
                                  <label className="text-muted">Type:</label>
                                  <span>{item?.size?.name ?? "N/A"}</span>
                                </div>
                              </div>
                                    </Col>
                                  ))
                                ) : (
                                  <span>N/A</span>
                                )}
                                </Row>
                            
                                <label className="mb-2 mt-6 fw-bold" style={{ fontSize: '16px', paddingLeft: 0, marginTop:"10px"}}>Career/Business Info</label>
                                  <Row style={{ paddingLeft: 0, paddingTop: 10 }}>
                                <Col xs={12}>
                            <div className="card p-2 shadow-sm rounded border mt-2">
                                {/* Product Info */}
                                <div className="d-flex justify-content-between mb-2">
                                  <label className="text-muted">Job:</label>
                                  <span>{details?.preference?.jobTitle ? details?.preference?.jobTitle : "N/A"}</span>
                                </div>
                            
                                <div className="d-flex justify-content-between mb-2">
                                  <label className="text-muted">Industry:</label>
                                  <span>{details?.preference?.industry?.name ? details?.preference?.industry?.name : "N/A"}</span>
                                </div>
                            
                                <div className="d-flex justify-content-between mb-2">
                                  <label className="text-muted">Linkedin Profile:</label>
                                  <span>{details?.preference?.linkedInProfile ? details?.preference?.linkedInProfile : "N/A"}</span>
                                </div>
                            
                                <div className="d-flex justify-content-between mb-2">
                                  <label className="text-muted">Business Detail:</label>
                                  <Tooltip
                                            title={details?.preference?.businessInfo ? <h6 style={{ color: "white" }}>{details?.preference?.businessInfo}</h6> : "N/A"}
                                            arrow
                                          >
                                  <span> {details?.preference?.businessInfo?.length > 40
                                  ? `${details?.preference?.businessInfo?.slice(0, 40)}...`
                                  : details?.preference?.businessInfo ? details?.preference?.businessInfo : "N/A"}</span>
                                          </Tooltip>
                                </div>
                              </div>
                                    </Col>
                                </Row>
                                </>
                                )}
          </Card.Body>
        </Card>
        </>
        )}
      </div>
    </Modal>,
    modalsRoot,
  );
};

export default SquezzMeInfoDetails;
