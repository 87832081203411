import { useLocation, useNavigate } from "react-router-dom";
import CategoryCard from "./CategoryCard";
import "./Preference.css";
import { getCategories } from "../../api";
import { ListGroup } from "react-bootstrap";
// import CategoryCardView from "../category/CatergoryCardView";
import { useQuery } from "react-query";
import React, { useEffect, useState } from "react";
import UserPreferencesCategoryCardView from "./userPreferencesCategory";
import { categoryDataForGolf } from "./CategoryData";
import './CategoryData.css';
import { Box } from "@mui/material";

const Preference: React.FC = () => {
  const [selectedCategory, setSelectedCategory] = useState<any>(null);
  const [categoryModel, setSelectedCategoryName] = useState<any>(null);
  const [reorderedCategoryData, setReorderedCategoryData] = useState<any>()
  // useEffect(() => {
  //   if (!selectedCategory) {
  //     setSelectedCategory(process.env.REACT_APP_ID_GOLF_CATE);
  //   }
  // }, [selectedCategory]);

  const location = useLocation();

  const categoryInfo : any = location?.state;

  useEffect(() => {
    if(categoryInfo?.selectedCategoryInfo && categoryData){
      setSelectedCategory(categoryInfo?.selectedCategoryInfo);
      const category = categoryData?.data?.docs?.find(
        (c: any) => c?._id == categoryInfo?.selectedCategoryInfo
      );
      setSelectedCategoryName(category);
    }
  },[categoryInfo])

  const { data: categoryData } = useQuery("category", getCategories);

  const UserPreferencesData = [
    {
      catKey: "Dishes",
      title: "Dishes",
      description: "Incorporate Dishes into your areas of interest.",
    },
    {
      catKey: "Dinning",
      title: "Dinning",
      description: "Incorporate Dining into your areas of interest.",
    },
    {
      catKey: "Wines",
      title: "Wines",
      description: "Incorporate Wines into your areas of interest.",
    },
    // {
    //     catKey : 'favouritefoodStyle',
    //     title: 'Favourite Food Style',
    //     description: 'Incorporate Favourite Food Styl into your areas of interest.'
    // },
    // {
    //     catKey : 'Setting',
    //     title: 'Setting',
    //     description: 'Incorporate Setting into your areas of interest.'
    // },
    {
      catKey: "Music",
      title: "Music",
      description: "Incorporate Music into your areas of interest.",
    },
    // {
    //     catKey : 'TypeOfMusic',
    //     title: 'Type Of Music',
    //     description: 'Incorporate Music into your areas of interest.'
    // },
  ];

  const navigate = useNavigate();
  const handleCategoryClick = (value: any, categoryId: any , selectedCategory:any) => {
    if (selectedCategory != null) {
      navigate("/userPreference_details", { state: {categoryValue : value, selectedCategoryInfo : selectedCategory }});
      localStorage.setItem("selectedCategory", selectedCategory);
    }
  };

  const selectCategoryClick = (value: any) => {
    if (value == process.env.REACT_APP_ID_REST_CATE) {
      setSelectedCategory(value);
      const category = categoryData?.data?.docs?.find(
        (c: any) => c?._id == value
      );
      setSelectedCategoryName(category);
    } else if (value == process.env.REACT_APP_ID_GOLF_CATE) {
      setSelectedCategory(value);
    } else {
      setSelectedCategory(null);
    }
  };

  const customOrder = [
    "65c608806782899b0698f069", // Restaurants
    "65c61866ea562b9cfd579468", // Golf
    "65c617ba6782899b069910fd", // Hotels
    "6633225437f7eb145bc1e92f"  // Wellness
  ];

  useEffect(() => {
    if(categoryData){
      const reorderedCategory = customOrder?.map(id => categoryData?.data?.docs?.find((doc: any) => doc._id === id));
      setReorderedCategoryData(reorderedCategory)
    }
  },[categoryData])

  return (
    <div>
      <div>
        <div className="pb-5 d-flex align-items-center justify-content-end"></div>
        <label className="form-label fw-bold" style={{ fontSize: "16px" }}>
          Select Category
        </label>
        <ListGroup>
          <ListGroup.Item
            style={{ backgroundColor: "transparent", border: "none", padding: "5px" }}
          >
            <div
              className="grid-container py-5"
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                gap: "16px",
                width: "100%",
                boxSizing: "border-box",
              }}
            >
              {reorderedCategoryData?.map((category: any) => (
                <UserPreferencesCategoryCardView
                  key={category.id}
                  category={category}
                  isSelected={selectedCategory === category._id}
                  onClick={selectCategoryClick}
                />
              ))}
            </div>
          </ListGroup.Item>
        </ListGroup>  
      </div>
      {selectedCategory != null && selectedCategory !== process.env.REACT_APP_ID_GOLF_CATE && (
          <Box p={1}>
          <label className="form-label fw-bold" style={{ fontSize: "16px" }}>
            User Preferences : {categoryModel?.name}{" "}
          </label>
          <div className="grid-container">
            {UserPreferencesData.map((category, index) => (
              <CategoryCard
                key={index}
                {...category}
                onClick={() =>
                  handleCategoryClick(category, categoryModel?._id,selectedCategory)
                }
              />
            ))}
          </div>
        </Box>
      )}

      {selectedCategory == process.env.REACT_APP_ID_GOLF_CATE && (
        <Box p={1}>
          {" "}
          <label className="form-label fw-bold" style={{ fontSize: "16px" }}>
            User Preferences : Golf {" "}
          </label>
          <div className="grid-container">
            {categoryDataForGolf.map((category, index) => (
              <CategoryCard
                key={index}
                {...category}
                onClick={() =>
                  handleCategoryClick(category, categoryModel?._id , selectedCategory)
                }
              />
            ))}
          </div>
        </Box>
      )}
    </div>
  );
};

export default Preference;
