import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { createPortal } from "react-dom";
import { useMutation } from "react-query";
import * as Yup from "yup";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { KTIcon } from "../../_metronic/helpers";
import AddIcon from "@mui/icons-material/Add";
import { addEditGolfHoles, restrictSlotRequest, uploadPicture } from "../../api";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";
import "react-datepicker/dist/react-datepicker.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { charValidate } from "../utility";
import TextField from "@mui/material/TextField";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import "react-datepicker/dist/react-datepicker.css";
import DeleteIcon from '@mui/icons-material/Delete';
import "./AddEditSlot.css";
import {
  convertTo24HourFormat,
  convertToAmPm,
  getDaysBetweenDates,
  parseDateWithoutTimezone,
} from "../../helpers/commonFunctions";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { validate } from "webpack";
import { Height } from "@mui/icons-material";
import { multiSectionDigitalClockSectionClasses } from "@mui/x-date-pickers";
interface IRestaurant {
  golfId: string;
  _id: string;
  holeNumber: number | string;
  picture: string[];
  description: string;
  distance: number | string;
  par: number | string;
}

interface TimeRange {
  tempId: number;
  _id: any;
  startTime: string;
  endTime: string;
}

interface ScheduleItem {
  // date: string[];  // Array of date strings
  _id: any;
  day: string;     // Day of the week
  timeRanges:
  TimeRange[]
}

type Props = {
  show: boolean;
  handleClose: (isSubmit: boolean) => void;
  editData: any;
  restaurantData: any;
};

const modalsRoot = document.getElementById("root-modals") || document.body;

const AddEditSlot = ({
  show,
  handleClose,
  editData,
  restaurantData,
}: Props) => {
  const [startDate, setStartDate] = useState<any>("");
  const [endDate, setEndDate] = useState<any>("");
  const [name, setName] = useState<any>("");
  const [startTimeErrorMessage, setStartTimeErrorMessage] = useState<any>("");
  const [nameErrorMessage, setNameErrorMessage] = useState<any>("");
  const [endTimeErrorMessage, setEndTimeErrorMessage] = useState<any>("");
  const [slotDays, setSlotDays] = useState<any>([]);
  const [addButtonDisable, setAddButtonDisable] = useState(false);
  const [validateForm, setValidateForm] = useState(false);
  const [scheduleList, setScheduleList] = useState<ScheduleItem[]>([]);
  const { mutateAsync: addEditSlotRequest, isLoading: isAddingRestrictSlotRequest } =
    useMutation("slot-restriction", restrictSlotRequest);
  const { mutateAsync: pictureUpload } = useMutation(
    "upload-image",
    uploadPicture
  );

  const slotStartDate = parseDateWithoutTimezone(startDate?.$d);
  const slotendtDate = parseDateWithoutTimezone(endDate?.$d);



  const validationSchema = Yup.object().shape({
    StartDate: Yup.string().required("Start date is required"),
    EndDate: Yup.string().required("End date is required"),
    name: Yup.string().required("Significant demand title is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      StartDate: "",
      EndDate: "",
      startTime: "",
      endTime: ""
    },
    validationSchema,
    onSubmit: async (values) => {
      if (!scheduleList?.length) {
        snackActions.error("Please select day to proceed further")
      }
      const isStartTimeHaveValues = scheduleList?.every((time: any) => time?.timeRanges?.every((data: any) => data?.startTime != ""));
      const isEndSlotValues = scheduleList?.every((time: any) => time?.timeRanges?.every((data: any) => data?.endTime != ""));
      if (!isStartTimeHaveValues || !isEndSlotValues || name != '') {
        setStartTimeErrorMessage("Start time is required")
        setEndTimeErrorMessage("End time is required")
        setNameErrorMessage("Significant demand title is required")
      }
      else {
        setStartTimeErrorMessage("")
        setEndTimeErrorMessage("")
        setNameErrorMessage("")
      }

      const slotDetails = [
        {
          startDate: slotStartDate,
          endDate: slotendtDate,
          name: values.name,
          days: scheduleList.map(day => ({
            day: day.day,
            timeRanges: day.timeRanges.map(timeRange => ({
              startTime: timeRange.startTime,
              endTime: timeRange.endTime
            }))
          }))
        }
      ];
      const editslotDetails = [
        {
          _id: editData?._id,
          startDate: slotStartDate,
          endDate: slotendtDate,
          name: editData?.name,
          days: scheduleList.map(day => ({
            _id: day._id,
            day: day.day,
            timeRanges: day.timeRanges.map((timeRange: any) => (
              {
                _id: timeRange._id,
                startTime: isCustomDateFormat(timeRange.startTime) ? formatEditTime(timeRange.startTime.toDate()) : timeRange?.startTime,
                endTime: isCustomDateFormat(timeRange.endTime) ? formatEditTime(timeRange.endTime.toDate()) : timeRange?.endTime
              }))
          }))
        }
      ];
      let AddDatabodyParams = {
        restaurantId: restaurantData?._id,
        restrictions: slotDetails
      };

      let editDatabodyParams = {
        restaurantId: restaurantData?._id,
        restrictions: editslotDetails
      };


      let bodyParams = editData?._id ? editDatabodyParams : AddDatabodyParams;

      if (isStartTimeHaveValues && isEndSlotValues && scheduleList?.length > 0) {
        try {
          let response = await addEditSlotRequest(bodyParams);

          if (response?.status) {
            snackActions.success(response?.message ?? "Added/Edited Successfully!");
            formik.resetForm(); // Reset form fields
            handleClose(true);  // Close the form or modal
            // Optionally reset image file names or other states here
            // setImgFileName("");
            // setHolesImg("");
          } else {
            snackActions.error(response?.message ?? "Something went wrong!");
          }
        } catch (error) {
          // Handle any unexpected errors that might occur during the API request
          snackActions.error("An unexpected error occurred.");
          console.error("Error during API request:", error);
        }
      }
    },
  });
  
  useEffect(() => {
    if (slotStartDate && slotendtDate) {
      const slotDays = getDaysBetweenDates(slotStartDate, slotendtDate);
      setSlotDays(slotDays);
    }
  }, [slotStartDate, slotendtDate]);

  const randomNumberInRange = (min: any, max: any) => {
    return Math.floor(Math.random()
      * (max - min + 1)) + min;
  };

  const isCustomDateFormat = (value: any) => {
    if (typeof value !== 'object' || value === null) {
      return false;
    }

    const hasExpectedProperties = value.hasOwnProperty('$D') &&
      typeof value.$D === 'number'

    return hasExpectedProperties;
  }

  const handleClick = (day: any) => {
    setScheduleList((prevList) => {
      const existingIndex = prevList.findIndex(item => item.day === day?.day);
      if (existingIndex !== -1) {
        return prevList.filter((_, index) => index !== existingIndex);
      } else {
        return [
          ...prevList,
          {
            // date: day?.date || [], 
            _id: 0,
            day: day?.day || '',
            timeRanges: [
              {
                _id: 0,
                tempId: randomNumberInRange(20, 60),
                startTime: '',
                endTime: ''
              }
            ]
          }
        ];
      }
    });
  };

  const convertToDayjs = (timeStr: any) => {
    return dayjs(timeStr, 'HH:mm'); // Adjust if the input format is different
  };

  useEffect(() => {
    if (editData) {
      formik.setFieldValue("StartDate", dayjs(editData?.startDate));
      formik.setFieldValue("EndDate", dayjs(editData?.endDate));
      setStartDate(dayjs(editData?.startDate));
      setEndDate(dayjs(editData?.endDate));
      formik.setFieldValue(
        "name",
        editData?.name
      );

      const slotDetails = editData?.days?.map((item: any) => {
        const timeRanges = item?.timeRanges?.map((timeData: any) => ({
          _id: timeData?._id,
          tempId: randomNumberInRange(20, 60),
          startTime: convertToDayjs(timeData?.startTime),
          endTime: convertToDayjs(timeData?.endTime)
        }));

        return {
          _id: item?._id,
          day: item?.day || '',
          timeRanges,
        };
      }) || [];
      setScheduleList(slotDetails);
    }
  }, [editData]);


  const getChipStyle = (chipLabel: any) => ({
    backgroundColor: scheduleList?.find((item: any) => item?.day == chipLabel?.day) ? "purple" : "",
    color: scheduleList?.find((item: any) => item?.day == chipLabel?.day) ? "white" : "",
  });

  function formatTime(date: any) {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const addHours =
      formattedHours < 10 ? "0" + formattedHours : formattedHours;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    return `${addHours}:${formattedMinutes} ${ampm}`;
  }

  function formatEditTime(date: any) {
    // Extract hours and minutes from the date object
    const hours = date.getHours();
    const minutes = date.getMinutes();

    // Format hours and minutes with leading zeros if necessary
    const formattedHours = hours < 10 ? "0" + hours : hours;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

    // Return the formatted time in 24-hour format
    return `${formattedHours}:${formattedMinutes}`;
  }

  const handleTimeChange = (index: number, tempId: number, fieldName: keyof TimeRange) => (newValue: dayjs.Dayjs | null) => {
    const timeString = newValue ? formatTime(newValue.toDate()) : '';
    const formattedTime = convertTo24HourFormat(timeString)
    const updatedScheduleList = [...scheduleList];

    const updatedTimeRange = updatedScheduleList[index].timeRanges.map(timeRangeItem =>
      timeRangeItem.tempId === tempId
        ? { ...timeRangeItem, [fieldName]: formattedTime }
        : timeRangeItem
    );

    updatedScheduleList[index] = {
      ...updatedScheduleList[index],
      timeRanges: updatedTimeRange
    };

    const isStartTimeHaveValues = updatedScheduleList?.every((time: any) => time?.timeRanges?.every((data: any) => data?.startTime != ""));
    const isEndSlotValues = updatedScheduleList?.every((time: any) => time?.timeRanges?.every((data: any) => data?.endTime != ""));

    if (isStartTimeHaveValues) {
      setStartTimeErrorMessage("")
    }
    if (isEndSlotValues) {
      setEndTimeErrorMessage("")
    }

    setScheduleList(updatedScheduleList)
  };

  const handleDeleteTime = (index: number, tempId: number) => {
    const updatedScheduleList = [...scheduleList];

    const updatedTimeRanges = updatedScheduleList[index].timeRanges.filter(
      (timeRangeItem) => timeRangeItem.tempId !== tempId
    );

    updatedScheduleList[index] = {
      ...updatedScheduleList[index],
      timeRanges: updatedTimeRanges,
    };

    setScheduleList(updatedScheduleList);
  };

  const handleAddTime = (index: number, data: any) => {
    const isSlotIsEmpty = data.timeRanges.every((range: any) => range.startTime != "" && range.endTime != "");
    if (!isSlotIsEmpty) {
      setAddButtonDisable(true)
    }
    else {
      const newTimeRange: TimeRange = {
        _id: 0,
        tempId: randomNumberInRange(61, 99),
        startTime: "",
        endTime: ""
      };

      const updatedScheduleList = [...scheduleList];

      if (index >= 0 && index < updatedScheduleList.length) {
        updatedScheduleList[index] = {
          ...updatedScheduleList[index],
          timeRanges: [...updatedScheduleList[index].timeRanges, newTimeRange]
        };

        setScheduleList(updatedScheduleList)
      } else {
        console.error('Index out of bounds');
      }
    }

  };

  // useEffect(() => {
  //   if (slotDays?.length > 0 && scheduleList?.length > 0, !editData?._id) {
  //     const slotDaysBetweenDates = slotDays?.map((days: any) => days?.day)

  //     const newScheduleList = scheduleList?.filter((listday: any) => slotDaysBetweenDates?.includes(listday?.day))
  //     setScheduleList(newScheduleList)
  //   }
  // }, [scheduleList, slotDays, !editData?._id])

  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-600px"
      show={show}
      onHide={() => {
        handleClose(false);
      }}
      backdrop="static"
    >
      <div className="modal-header">
        <h2>{editData?._id ? "Edit" : "Add"} Slot</h2>
        {/* <h2>Add Slot</h2> */}
        {/* begin::Close */}
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={() => {
            handleClose(false);
          }}
        >
          <KTIcon className="fs-1" iconName="cross" />
        </div>
        {/* end::Close */}
      </div>

      <div className="p-6" style={{ maxHeight: "60vh", overflowY: "auto" }}>
        <form onSubmit={formik.handleSubmit} noValidate className="form">
          <div>
            <Row>
              <Col xs={6}>
                <label className="form-label fw-bold">Significant Demand Title</label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Enter significant demand title"
                  aria-label="Name"
                  {...formik.getFieldProps("name")}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.name}</div>
                  </div>
                )}
              </Col>
            </Row>
            <div className="d-flex">

              <div style={{ paddingRight: "10px", paddingTop: "10px" }}>

                <label className="form-label fw-bold">Start Date</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    name="StartDate"
                    value={formik?.values?.StartDate}
                    onChange={(date) => {
                      formik.setFieldValue("StartDate", date);
                      formik.setFieldValue("EndDate", null); 
                      setStartDate(date);
                      setEndDate('');
                      setScheduleList([]);
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        error: false,
                      },
                    }}
                    views={["year", "month", "day"]}
                    disablePast
                    sx={{
                      "&.MuiFormControl-root": {
                        width: "100%",
                      },
                      border: "1px solid #919EAB",
                      borderRadius: "0.475rem !important",
                      height: "3.4rem !important",
                      padding: "2px 2px !important",
                    }}
                  />
                </LocalizationProvider>
                {formik.touched.StartDate && formik.errors.StartDate && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.StartDate}
                    </div>
                  </div>
                )}
              </div>
              <div style={{ paddingRight: "10px", paddingTop: "10px" }}>
                <label className="form-label fw-bold">End Date</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    name="EndDate"
                    value={formik?.values?.EndDate}
                    onChange={(date) => {
                      formik.setFieldValue("EndDate", date);
                      setEndDate(date);
                    }}
                    minDate={startDate}
                    slotProps={{
                      textField: {
                        size: "small",
                        error: false,
                      },
                    }}
                    views={["year", "month", "day"]}
                    disablePast
                    sx={{
                      "&.MuiFormControl-root": {
                        width: "100%",
                      },
                      border: "1px solid #919EAB",
                      borderRadius: "0.475rem !important",
                      height: "3.4rem !important",
                      padding: "2px 2px !important",
                    }}
                  />
                </LocalizationProvider>
                {formik.touched.EndDate && formik.errors.EndDate && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.EndDate}</div>
                  </div>
                )}
              </div>
            </div>

            <div className="pt-6 pb-6">
              <Stack direction="row" spacing={1}>
                {slotDays?.map((detail: any, index: any) => (
                  <Chip
                    key={index}
                    label={detail?.day?.slice(0, 3)}
                    onClick={() => handleClick(detail)}
                    style={getChipStyle(detail)}
                  />
                ))}
              </Stack>
            </div>
            {scheduleList?.map((item: any, index: any) => (
              <div className="time-slot-details" key={index}>
                <div className="day-header">
                  <div className="header-title">{item?.day}</div>
                  <div className="add-time-slot">
                    <Button
                      size="sm"
                      variant="primary"
                      className="time-slot-add-button"
                      onClick={() => handleAddTime(index, item)}
                      disabled={!(item.timeRanges.every((range: any) => range.startTime != "" && range.endTime != ""))}
                    >
                      <AddIcon />
                    </Button>
                  </div>
                </div>
                <div className="d-flex flex-column pt-2">
                  {item?.timeRanges?.map((data: any, timeindex: any) => (
                    <div className="d-flex time-slot-row" key={timeindex}>
                      <div className="custom-margin">
                        <label className="form-label fw-bold d-block">Start Time</label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["TimePicker"]}>
                            <TimePicker
                              ampm={false}
                              className="slot-time-picker"
                              onChange={handleTimeChange(index, data?.tempId, 'startTime')}
                              value={convertToDayjs(data?.startTime)}
                              timeSteps={{ minutes: 1 }}
                              slotProps={{
                                layout: {
                                  sx: {
                                    [`.${multiSectionDigitalClockSectionClasses.root}:after`]: {
                                      display: "none"
                                    }
                                  }
                                }
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                        {data?.startTime === "" && startTimeErrorMessage !== "" && (
                          <label style={{ color: "#fa4f4f", fontSize: "1rem", fontWeight: "400" }}>
                            {startTimeErrorMessage}
                          </label>
                        )}
                      </div>
                      <div className="custom-margin">
                        <label className="form-label fw-bold d-block">End Time</label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["TimePicker"]}>
                            <TimePicker
                              ampm={false}
                              className="slot-time-picker"
                              onChange={handleTimeChange(index, data?.tempId, 'endTime')}
                              value={data?.endTime}
                              timeSteps={{ minutes: 1 }}
                              minTime={data?.startTime ? convertToDayjs(data?.startTime) : undefined}
                              disabled={!data?.startTime}
                              slotProps={{
                                layout: {
                                  sx: {
                                    [`.${multiSectionDigitalClockSectionClasses.root}:after`]: {
                                      display: "none"
                                    }
                                  }
                                }
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                        {data?.endTime === "" && endTimeErrorMessage !== "" && (
                          <label style={{ color: "#fa4f4f", fontSize: "1rem", fontWeight: "400" }}>
                            {endTimeErrorMessage}
                          </label>
                        )}
                      </div>
                      {timeindex !== 0 && (
                        <div className="delete-time-slot" onClick={() => handleDeleteTime(index, data?.tempId)}>
                          <DeleteIcon />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>



          <div className="w-100 d-flex align-items-center justify-content-end">
            <Button
              onClick={() => {
                formik.resetForm();
                handleClose(false);
              }}
              size="lg"
              variant="secondary"
            >
              Cancel
            </Button>
            <div className="w-15px"></div>
            <Button type="submit" size="lg" variant="primary">
              {isAddingRestrictSlotRequest ? "Loading..." : "Submit"}
            </Button>
          </div>
        </form>
      </div>
    </Modal>,
    modalsRoot
  );
};

export default AddEditSlot;
