import { format } from "date-fns";
import dayjs from "dayjs";
import moment from "moment";

const formatDate = (
  date: string | undefined | null,
  formatString?: string,
  addTimeZoneOffset = false,
  shouldConvertToCST = false,
) => {
  if (!date) return "";
  try {
    const dt = new Date(date);
    if (shouldConvertToCST) {
      let usaTime = dt.toLocaleString("en-US", {
        timeZone: "CST",
      });
      return format(
        new Date(usaTime),
        formatString ? formatString : "MMM dd yyyy",
      );
    }
    const dtDateOnly = new Date(
      addTimeZoneOffset
        ? dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000
        : dt,
    );
    return format(dtDateOnly, formatString ? formatString : "MMM dd yyyy");
  } catch (e) {
    console.log(e);
    return "";
  }
};

const formatCurrency = (
  amount: number = 0,
  currency?: string,
  isIntegerOnly?: boolean,
) => {
  amount = isNaN(amount) ? 0 : amount;
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency ? currency : "USD",
    minimumFractionDigits: isIntegerOnly ? 0 : 2,
  });
  return formatter.format(amount);
};

function parseDateWithoutTimezone(date: string | Date, format?: string) {
  if (typeof date === "string") {
    return moment(date).format(format ? format : "YYYY-MM-DD");
  } else {
    return moment(date, "YYYY-MM-DD").format(format ? format : "YYYY-MM-DD");
  }
}

function convertTo24HourFormat(timeString: string){
  const [time, period] = timeString.split(' ');
  const [hour, minute] = time.split(':');
  let formattedHour = parseInt(hour);

  if (period === 'PM' && formattedHour !== 12) {
    formattedHour += 12;
  } else if (period === 'AM' && formattedHour === 12) {
    formattedHour = 0;
  }

  const formattedHourString = formattedHour.toString().padStart(2, '0');

  return `${formattedHourString}:${minute}`;
}

function convertDayjsFormatTimeTo24HourFormat(timeString: string){
  const date = new Date(timeString);

  const hours = date.getHours();
  const minutes = date.getMinutes();

  const paddedMinutes = minutes < 10 ? '0' + minutes : minutes;

  return `${hours}:${paddedMinutes}`;
}

function convertToDayjsFormat(timeString: any) {
  // Parse the 24-hour format time string (HH:mm)
  const date = dayjs(timeString, 'HH:mm');

  // Check if the date is valid
  if (date.isValid()) {
    return date;
  } else {
    console.error('Invalid date');
    return null;
  }
}

function convertToAmPm(time24: any) {

  const [hours24, minutes] = time24.split(':').map(Number);

  const date = new Date();
  date.setHours(hours24);
  date.setMinutes(minutes);

  let hours12 = date.getHours() % 12 || 12; 
  const ampm = date.getHours() >= 12 ? 'PM' : 'AM';

  const minutesFormatted = date.getMinutes().toString().padStart(2, '0');

  return `${hours12}:${minutesFormatted} ${ampm}`;
}


function getDaysBetweenDates(startDate: any, endDate: any) {
  const resultMap = new Map<string, string[]>(); // Map to store days of the week and their corresponding dates

  let currentDate = new Date(startDate); // Initialize currentDate as a Date object

  while (currentDate <= new Date(endDate)) { // Ensure both are Date objects
      const formattedDate = currentDate.toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/\//g, '/');
      const dayOfWeek = currentDate.toLocaleDateString('en-US', { weekday: 'long' });

      // If the day of the week is not yet in the map, initialize an empty array for it
      if (!resultMap.has(dayOfWeek)) {
          resultMap.set(dayOfWeek, []);
      }

      // Add the date to the corresponding day of the week array
      resultMap.get(dayOfWeek)?.push(formattedDate);

      // Move to the next date
      currentDate.setDate(currentDate.getDate() + 1);
  }

  // Convert the map to the desired output format
  const result = Array.from(resultMap.entries()).map(([day, dates]) => ({
      day,
      date: dates
  }));

  return result;
}

const convertDateToMMDDYYYY = (dateString: string): any => {
  const parsedDate = new Date(dateString);

  if (parsedDate.toString() === 'Invalid Date') {
    return null;
  }
  return format(parsedDate, 'MM/dd/yyyy');
};


 const formatPhoneNumber = (countryCode: string,phone: string): string => {
  const formattedCountryCode = countryCode.startsWith('+') ? countryCode : `+${countryCode}`;
  const match = phone.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `${formattedCountryCode}(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return `${formattedCountryCode}${phone}`; 
};

function convertToAMPM(dateString: string): string {
  const date = new Date(dateString);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  
  const formattedMinutes = minutes < 10 ? '0' + minutes : minutes.toString();

  return `${hours}:${formattedMinutes} ${ampm}`;
}

export { formatCurrency, convertDayjsFormatTimeTo24HourFormat, convertToDayjsFormat, formatDate , convertToAMPM, parseDateWithoutTimezone, getDaysBetweenDates, convertTo24HourFormat, convertToAmPm, convertDateToMMDDYYYY,formatPhoneNumber};
