import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { Box, Tooltip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useRef, useState } from "react";
import { Card } from "react-bootstrap";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { KTCard } from "../../_metronic/helpers";
import ConfirmEventPopup from "../../_metronic/layout/components/common/ConfirmEventPopup";
import CustomTooltip from "../../_metronic/layout/components/common/CustomTooltip";
import {
  activeDeActiveGolfClub,
  deleteCategoryRestaurant,
  getCategoryRestaurant,
} from "../../api";
import MuiTable from "../../components/table/MuiTable";
import { charValidate } from "../../components/utility";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";
import useDebounce from "../../hooks/useDebounce";
import RestaurantIcon from "@mui/icons-material/GolfCourse";
import AddEditRestaurant from "../Restaurant/AddEditRestaurant";
import { setData } from "../../store/slice/header/headerSlice";
import { useDispatch } from "react-redux";

const Health_care_list: React.FC = () => {
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [showActiveDeActiveGolfPopup, setShowActiveDeActiveGolfPopup] =
    useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();

  const [tableOptions, setTableOptions] = useState({
    offset: 0,
    limit: 10,
    hasNextPage: true,
    hasPrevPage: true,
    totalRowCount: undefined,
    type: "",
    golfType: "",
    search: "",
  });
  const tableRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const debounceSearchValue: string = useDebounce(tableOptions?.search, 500);

  const {
    mutateAsync: getRestaurantData,
    data: RestaurantData,
    isLoading: isGettingRestaurantData,
  } = useMutation("restaurant-list", getCategoryRestaurant);

  const { mutateAsync: deleteRestaurantData, isLoading: isDeletingRestaurant } =
    useMutation("delete-restaurant", deleteCategoryRestaurant);
  const { mutateAsync: activeDeActiveGolf, isLoading: isActiveDeActiveGolf } =
    useMutation("active-golf", activeDeActiveGolfClub);

  useEffect(() => {
    getRestaurant(tableOptions);
  }, []);

  useEffect(() => {
    getRestaurant({
      ...tableOptions,
      search: debounceSearchValue ? debounceSearchValue : "",
    });
  }, [tableOptions?.type, tableOptions?.golfType, debounceSearchValue]);

  const getRestaurant = async (
    query:
      | {
          offset: number | string;
          limit: number;
          search: string;
        }
      | undefined
  ) => {
    let response = await getRestaurantData(query);
    if (response?.status) {
      setTableOptions((prevState: any) => {
        return {
          ...prevState,
          offset: response?.data?.offset,
          limit: response?.data?.limit,
          hasNextPage: response?.data?.hasNextPage,
          hasPrevPage: response?.data?.hasPrevPage,
          totalRowCount: response?.data?.totalDocs,
        };
      });
    }
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1.3,
      sortable: false,
      renderCell: (data) => (
        <CustomTooltip
          title={
            data?.row?.name ? (
              <h6 style={{ color: "white" }}>{data?.row?.name}</h6>
            ) : (
              "N/A"
            )
          }
        >
          <span>
            {data?.row?.name ? charValidate(data?.row?.name, 30) : "N/A"}
          </span>
        </CustomTooltip>
      ),
    },

    {
      field: "mobileNumber",
      headerName: "Mobile Number",
      flex: 0.8,
      sortable: false,
      renderCell: (data: any) => data?.row?.mobileNumber ?? "N/A",
    },
    {
      field: "address",
      headerName: "Address",
      flex: 1.7,
      sortable: false,
      renderCell: (data: any) =>
        data?.row?.address.name +
        "," +
        data?.row?.address.street +
        "," +
        data?.row?.address.city +
        "," +
        data?.row?.address.state +
        "," +
        data?.row?.address.country,
    },
    {
      field: "type",
      headerName: "Type",
      flex: 0.8,
      sortable: false,
      renderCell: (data: any) => data?.row?.type ?? "N/A",
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.7,
      sortable: false,
      headerAlign: "right",
      align: "right",
      renderCell: (data) => (
        <Box display="flex" alignItems="center">
          <Tooltip title="Make Restaurant Active/De-Active">
            <RestaurantIcon
              onClick={() => {
                setShowActiveDeActiveGolfPopup(true);
              }}
              sx={{ fontSize: 22, mr: 1.7, cursor: "pointer" }}
              color="primary"
            />
          </Tooltip>
          <Tooltip title="Edit">
            <DriveFileRenameOutlineIcon
              onClick={() => {
                setSelectedRow(data.row);
                setShowAddEditModal(true);
              }}
              sx={{ fontSize: 22, cursor: "pointer" }}
              color="primary"
            />
          </Tooltip>
          <Tooltip title="Delete">
            <DeleteSweepIcon
              onClick={() => {
                setSelectedRow(data.row);
                setShowDeleteConfirmModal(true);
              }}
              sx={{ fontSize: 22, ml: 1.7, cursor: "pointer" }}
              color="error"
            />
          </Tooltip>
        </Box>
      ),
    },
  ];

  const handleModalClose = (isSubmit: boolean) => {
    setShowAddEditModal(false);
    setSelectedRow("");
    if (isSubmit) {
      let query = {
        offset: tableOptions?.offset ?? 0,
        limit: tableOptions?.limit ?? 10,
        type: tableOptions?.type ?? "",
        golfType: tableOptions?.golfType ?? "",
        search: debounceSearchValue ?? "",
      };
      getRestaurant(query);
    }
  };

  const handleCloseDelete = () => {
    setShowDeleteConfirmModal(false);
    setSelectedRow("");
  };

  const handleCloseActiveGolfPopup = () => {
    setShowActiveDeActiveGolfPopup(false);
    setSelectedRow("");
  };

  const deleteRestaurant = async () => {
    let response = await deleteRestaurantData({ id: selectedRow?._id });
    if (response?.status) {
      snackActions.success(response?.message);
      handleCloseDelete();
      let query = {
        offset: tableOptions?.offset ?? 0,
        limit: tableOptions?.limit ?? 10,
        type: tableOptions?.type ?? "",
        golfType: tableOptions?.golfType ?? "",
        search: debounceSearchValue ?? "",
      };
      getRestaurant(query);
    } else {
      snackActions.error(response?.message);
    }
  };

  const handlePagination = (values: { page: number; pageSize: number }) => {
    let page = values?.page;
    let pageSize = values?.pageSize;
    let query = {
      offset:
        pageSize == tableOptions?.limit ? (page === 0 ? 0 : `${page}` + 0) : 0,
      limit: pageSize ?? 10,
      type: tableOptions?.type ?? "",
      golfType: tableOptions?.golfType ?? "",
      search: debounceSearchValue ?? "",
    };
    getRestaurant(query);
  };

  const handleChangeFilters = (fieldName: string, value: string) => {
    setTableOptions((prevState: any) => {
      return {
        ...prevState,
        [fieldName]: value,
      };
    });
  };

  const handleBackClick = () => {
    navigate("/category");
    dispatch(setData("Category"));
  };

  return (
    <Card className="p-6">
      <>
        <KTCard>
          <MuiTable
            addLabel="Add Health Care"
            showComingSoon={true }
            handleAddClick={() => setShowAddEditModal(true)}
            columns={columns}
            data={RestaurantData?.data?.docs ?? []}
            tableRef={tableRef}
            isBackEnable={true}
            handleBackClick={handleBackClick}
            loading={isGettingRestaurantData}
            isRowClickable={true}
            handlePagination={handlePagination}
            tableOptions={tableOptions}
            filters={
              <Box pb={3} display="flex" alignItems="center">
                <Box>
                  <label
                    className="form-label fw-bold"
                    style={{ marginLeft: 5 }}
                  >
                    Search By Name
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    placeholder="Search by name"
                    aria-label="Search"
                    onChange={(e: any) =>
                      handleChangeFilters("search", e?.target?.value)
                    }
                    value={tableOptions?.search}
                  />
                </Box>
              </Box>
            }
          />
        </KTCard>
        {showAddEditModal && (
          <AddEditRestaurant
            editData={selectedRow}
            show={showAddEditModal}
            handleClose={handleModalClose}
          />
        )}
        {showDeleteConfirmModal && (
          <ConfirmEventPopup
            showPopup={showDeleteConfirmModal}
            handleClose={handleCloseDelete}
            handleConfirmed={deleteRestaurant}
            loading={isDeletingRestaurant}
            title={"Delete Restaurant"}
            message={"Are you sure, you want to delete this restaurant?"}
          />
        )}
      </>
    </Card>
  );
};

export default Health_care_list;
