import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { createPortal } from "react-dom";
import { MultiSelect } from "react-multi-select-component";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useMutation, useQuery } from "react-query";
import "react-quill/dist/quill.snow.css";
import * as Yup from "yup";
import { KTIcon } from "../../_metronic/helpers";
import {
  addAdmin,
  editAdmin,
  getAllGolfClub,
  getAllHotelList,
  getAllWellnessList,
  getCategories,
  getCategoryRestaurant,
} from "../../api";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";
import { isAdmin } from "../../hooks/useUserInfo";
import { Box, CircularProgress } from "@mui/material";

type Props = {
  show: boolean;
  handleClose: (isSubmit: boolean) => void;
  title?: string | undefined;
  isForEditProfile?: boolean;
  isForMakeSuperUser?: boolean;
  editData: {
    _id?: string | undefined;
    firstName: string;
    lastName: string;
    email: string;
    mobileNumber: any;
    countryCode: any;
    password: string;
    // logo: string;
    // bio: string;
    // dateOfBirth: string;
    countryId: number;
    stateId: number;
    cityId: number;
    zip: number;
    manageGolfs: string[];
    manageRestaurants: string[];
    manageWellness: string[];
    manageHotels: string[];
    superUserGolfs: string[];
    superUserRestaurants: string[];
    superUserWellness: string[];
    superUserHotels: string[];
    role: string[];
  };
};

interface ISelectedGolf {
  label: string;
  value: string;
}

const modalsRoot = document.getElementById("root-modals") || document.body;

const AddEditAdmin = ({
  show,
  handleClose,
  editData,
  title,
  isForEditProfile,
  isForMakeSuperUser
}: Props) => {
  const [img, setImg] = useState();
  const [selectedGolfs, setSelectedGolf] = useState<ISelectedGolf[] | []>([]);
  const [selectedWellnessValue, setSelectedWellnessValue] = useState<
    any[] | []
  >([]);
  const [selectedHotelValue, setSelectedHotelValue] = useState<
    any[] | []
  >([]);
  const [selectedCategoryValue, setSelectedCategoryValue] = useState("");
  const [isCategorySelected, setIsCategorySelected] = useState("");
  const [selectedRestaurantValue, setSelectedRestaurantValue] = useState<
    ISelectedGolf[] | []
  >([]);

  const userInfoValue: any = localStorage.getItem('userInfo')
  const roleValue = JSON.parse(userInfoValue)?.role
  const {
    mutateAsync: getRestaurantData,
    data: restaurantData,
    isLoading: isLoadingRestaurant,
    error: restaurantAPIError,
  } = useMutation("restaurant-list", getCategoryRestaurant);

  const {
    mutateAsync: getGolfClubs,
    data: golfData,
    isLoading: isGettingGolfData,
  } = useMutation("golf-list", getAllGolfClub);

  const {
    mutateAsync: getWellnessData,
    data: wellnessDropdownData,
    isLoading: isLoadingWellness,
    error: wellnessAPIError,
  } = useMutation("wellness-list", getAllWellnessList);

  const {
    mutateAsync: getHotelData,
    data: hotelnessDropdownData,
    isLoading: isGettingHotelData,
  } = useMutation("hotel-list", getAllHotelList)


  useEffect(() => {
    getCategories();
  }, []);

  const [tableOptions, setTableOptions] = useState({
    offset: 0,
    hasNextPage: true,
    hasPrevPage: true,
    totalRowCount: undefined,
    search: "",
  });

  useEffect(() => {
    getRestaurant({ offset: 0, limit: 1000, search: "" });
  }, []);

  const getRestaurant = async (
    query:
      | {
        offset: number | string;
        limit: number | string;
        search: string;
      }
      | undefined
  ) => {
    let response = await getRestaurantData(query);
    if (response?.status) {
      setTableOptions((prevState: any) => {
        return {
          ...prevState,
          offset: response?.data?.offset,
          limit: response?.data?.limit,
          hasNextPage: response?.data?.hasNextPage,
          hasPrevPage: response?.data?.hasPrevPage,
          totalRowCount: response?.data?.totalDocs,
        };
      });
    }
  };

  useEffect(() => {
    getGolfClubs({ limit: 1000 });
  }, []);

  useEffect(() => {
    getWellnessData({ limit: 1000 });
  }, []);

  useEffect(() => {
    getHotelData({ limit: 1000 });
  }, []);

  useEffect(() => {
    if (editData && golfData && (isAdmin ? window.location.pathname != "/super-user" : window.location.pathname != "/app-user/super-user") && !isForMakeSuperUser) {
      let golfList = golfData?.data?.docs;
      if (golfList && golfList?.length > 0) {
        let selectedGolfList: any = [];
        golfList?.forEach((golf: any) => {
          if (editData?.manageGolfs?.includes(golf?._id)) {
            selectedGolfList?.push({ label: golf?.name, value: golf?._id });
          }
        });
        setSelectedGolf(selectedGolfList);
        formik.setFieldValue('assignedGolfs', selectedGolfList);
      }
      // setSelectedGolf(editData?.manageGolfs ?? [])
    }
  }, [editData, golfData]);

  useEffect(() => {
    if (editData && golfData && (isAdmin ? window.location.pathname == "/super-user" : window.location.pathname == "/app-user/super-user")) {
      let golfList = golfData?.data?.docs;
      if (golfList && golfList?.length > 0) {
        let selectedGolfList: any = [];
        golfList?.forEach((golf: any) => {
          if (editData?.superUserGolfs?.includes(golf?._id)) {
            selectedGolfList?.push({ label: golf?.name, value: golf?._id });
          }
        });
        setSelectedGolf(selectedGolfList);
        formik.setFieldValue('assignedGolfs', selectedGolfList);
      }
      // setSelectedGolf(editData?.manageGolfs ?? [])
    }
  }, [editData, golfData]);

  useEffect(() => {
    if (editData && restaurantData && (isAdmin ? window.location.pathname != "/super-user" : window.location.pathname != "/app-user/super-user") && !isForMakeSuperUser) {
      let restaurantList = restaurantData?.data?.docs;
      if (restaurantList && restaurantList?.length > 0) {
        let selectedRestaurantList: any = [];
        restaurantList?.forEach((restaurant: any) => {
          if (editData?.manageRestaurants?.includes(restaurant?._id)) {
            selectedRestaurantList?.push({
              label: restaurant?.name,
              value: restaurant?._id,
            });
          }
        });
        setSelectedRestaurantValue(selectedRestaurantList);
        formik.setFieldValue('assignedRestaurants', selectedRestaurantList);
      }
    }
  }, [editData, restaurantData]);

  useEffect(() => {
    if (editData && restaurantData && (isAdmin ? window.location.pathname == "/super-user" : window.location.pathname == "/app-user/super-user")) {
      let restaurantList = restaurantData?.data?.docs;
      if (restaurantList && restaurantList?.length > 0) {
        let selectedRestaurantList: any = [];
        restaurantList?.forEach((restaurant: any) => {
          if (editData?.superUserRestaurants?.includes(restaurant?._id)) {
            selectedRestaurantList?.push({
              label: restaurant?.name,
              value: restaurant?._id,
            });
          }
        });
        setSelectedRestaurantValue(selectedRestaurantList);
        formik.setFieldValue('assignedRestaurants', selectedRestaurantList);
      }
    }
  }, [editData, restaurantData]);

  useEffect(() => {
    if (editData && wellnessDropdownData && (isAdmin ? window.location.pathname != "/super-user" : window.location.pathname != "/app-user/super-user") && !isForMakeSuperUser) {
      let wellnessList = wellnessDropdownData?.data?.docs;
      if (wellnessList && wellnessList?.length > 0) {
        let selectedWellnessList: any = [];
        wellnessList?.forEach((wellness: any) => {
          if (editData?.manageWellness?.includes(wellness?._id)) {
            selectedWellnessList?.push({
              label: wellness?.name,
              value: wellness?._id,
            });
          }
        });
        setSelectedWellnessValue(selectedWellnessList);
        formik.setFieldValue('assignedWellness', selectedWellnessList);
      }
    }
  }, [editData, wellnessDropdownData]);

  useEffect(() => {
    if (editData && wellnessDropdownData && (isAdmin ? window.location.pathname == "/super-user" : window.location.pathname == "/app-user/super-user")) {
      let wellnessList = wellnessDropdownData?.data?.docs;
      if (wellnessList && wellnessList?.length > 0) {
        let selectedWellnessList: any = [];
        wellnessList?.forEach((wellness: any) => {
          if (editData?.superUserWellness?.includes(wellness?._id)) {
            selectedWellnessList?.push({
              label: wellness?.name,
              value: wellness?._id,
            });
          }
        });
        setSelectedWellnessValue(selectedWellnessList);
        formik.setFieldValue('assignedWellness', selectedWellnessList);
      }
    }
  }, [editData, wellnessDropdownData]);


  useEffect(() => {
    if (editData && hotelnessDropdownData && (isAdmin ? window.location.pathname != "/super-user" : window.location.pathname != "/app-user/super-user") && !isForMakeSuperUser) {
      let hotelList = hotelnessDropdownData?.data?.docs;
      if (hotelList && hotelList?.length > 0) {
        let selectedHotelList: any = [];

        hotelList?.forEach((wellness: any) => {
          if (editData?.manageHotels?.includes(wellness?._id)) {
            selectedHotelList?.push({
              label: wellness?.name,
              value: wellness?._id,
            });
          }
        });
        setSelectedHotelValue(selectedHotelList);
        formik.setFieldValue('assignedHotels', selectedHotelList);
      }
    }
  }, [editData, hotelnessDropdownData]);

  useEffect(() => {
    if (editData && hotelnessDropdownData && (isAdmin ? window.location.pathname == "/super-user" : window.location.pathname == "/app-user/super-user")) {
      let hotelList = hotelnessDropdownData?.data?.docs;
      if (hotelList && hotelList?.length > 0) {
        let selectedHotelList: any = [];

        hotelList?.forEach((wellness: any) => {
          if (editData?.superUserHotels?.includes(wellness?._id)) {
            selectedHotelList?.push({
              label: wellness?.name,
              value: wellness?._id,
            });
          }
        });
        setSelectedHotelValue(selectedHotelList);
        formik.setFieldValue('assignedHotels', selectedHotelList);
      }
    }
  }, [editData, hotelnessDropdownData]);


  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Email is required"),
    mobileNumber: Yup.string()
      .required("Please enter mobile number")
      .test('mobile-number', 'Mobile number format is incorrect', function (value) {
        if (!value) return false;
        const cleanedNumber = value.replace(formik.values.countryCode, '').replace(/\D/g, '');
        if (cleanedNumber.length === 0) {
          return this.createError({ message: "Please enter mobile number" });
        }
        if (cleanedNumber.length < 10) {
          return false;
        }
        return true;
      }),
    assignedRestaurants: Yup.array()
      .nullable()
      .test('at-least-one-category', '', function (value) {
        const { assignedGolfs, assignedWellness, assignedHotels } = this.parent;
        if (
          (!value || value.length === 0) &&
          (!assignedGolfs || assignedGolfs.length === 0) &&
          (!assignedWellness || assignedWellness.length === 0) &&
          (!assignedHotels || assignedHotels.length === 0)
        ) {
          return this.createError({ message: "Please select at least one category" });
        }
        return true;
      }),

    assignedGolfs: Yup.array()
      .nullable()
      .test('at-least-one-category', '', function (value) {
        const { assignedRestaurants, assignedWellness, assignedHotels } = this.parent;
        if (
          (!value || value.length === 0) &&
          (!assignedRestaurants || assignedRestaurants.length === 0) &&
          (!assignedWellness || assignedWellness.length === 0) &&
          (!assignedHotels || assignedHotels.length === 0)
        ) {
          return this.createError({ message: "Please select at least one category" });
        }
        return true;
      }),

    assignedWellness: Yup.array()
      .nullable()
      .test('at-least-one-category', '', function (value) {
        const { assignedRestaurants, assignedGolfs, assignedHotels } = this.parent;
        if (
          (!value || value.length === 0) &&
          (!assignedRestaurants || assignedRestaurants.length === 0) &&
          (!assignedGolfs || assignedGolfs.length === 0) &&
          (!assignedHotels || assignedHotels.length === 0)
        ) {
          return this.createError({ message: "Please select at least one category" });
        }
        return true;
      }),

    assignedHotels: Yup.array()
      .nullable()
      .test('at-least-one-category', '', function (value) {
        const { assignedRestaurants, assignedGolfs, assignedWellness } = this.parent;
        if (
          (!value || value.length === 0) &&
          (!assignedRestaurants || assignedRestaurants.length === 0) &&
          (!assignedGolfs || assignedGolfs.length === 0) &&
          (!assignedWellness || assignedWellness.length === 0)
        ) {
          return this.createError({ message: "Please select at least one category" });
        }
        return true;
      }),
  });

  const userRoles = editData?.role || [];
  const formik: any = useFormik({
    initialValues: {
      firstName: editData?.firstName ?? "",
      lastName: editData?.lastName ?? "",
      email: editData?.email ?? "",
      mobileNumber: editData?.mobileNumber ?? "",
      countryCode: editData?.countryCode ?? "",
      assignedRestaurants: [],
      assignedGolfs: [],
      assignedWellness: [],
      assignedHotels: []
    },
    validationSchema,
    onSubmit: async (values) => {

      const isAnyCategorySelected = [selectedRestaurantValue, selectedGolfs, selectedHotelValue, selectedWellnessValue]
      .some(value => value?.length > 0);

      if(isAnyCategorySelected){
        setIsCategorySelected("")
      }
      else{
        setIsCategorySelected("Please select atleast one category")
      }
    
      let bodyParams = {
        userId: editData ? editData?._id : undefined,
        firstName: values?.firstName ?? "",
        lastName: values?.lastName ?? "",
        mobileNumber: values?.mobileNumber
          ? values?.mobileNumber?.slice(`${values?.countryCode?.length}`)
          : "",
        countryCode:
          values?.countryCode != null && values?.countryCode !== ""
            ? !values?.countryCode.includes("+")
              ? `+${values?.countryCode}`
              : values?.countryCode
            : "1",
        email: values?.email ?? "",
        type: (isAdmin ? window.location.pathname == "/super-user" : isForMakeSuperUser ? (window.location.pathname == "/app-user/app-customer" || window.location.pathname == "/app-user/app-admin") : window.location.pathname == "/app-user/super-user") ? "superUser" : "admin",
        // role: ["superAdmin"],
        ...(!isForEditProfile ? { role: isAnyCategorySelected ? userRoles : ["user"] } : {role: roleValue}),
        manageGolfs: isForEditProfile
          ? editData.manageGolfs
          : selectedGolfs?.length
            ? selectedGolfs?.map((golf: ISelectedGolf) => golf?.value)
            : [],
        manageRestaurants: isForEditProfile
          ? editData.manageRestaurants
          : selectedRestaurantValue?.length
            ? selectedRestaurantValue?.map(
              (restaurant: ISelectedGolf) => restaurant?.value
            )
            : [],
        manageWellness:
          isForEditProfile ? editData.manageWellness : selectedWellnessValue?.length ?
            selectedWellnessValue?.map((restaurant: any) => restaurant?.value)
            : [],

        manageHotels: isForEditProfile
          ? editData.manageHotels
          : selectedHotelValue?.length
            ? selectedHotelValue?.map(
              (restaurant: any) => restaurant?.value
            )
            : [],
        superUserGolfs: selectedGolfs?.length
            ? selectedGolfs?.map((golf: ISelectedGolf) => golf?.value)
            : [],
        superUserRestaurants: selectedRestaurantValue?.length
            ? selectedRestaurantValue?.map(
              (restaurant: ISelectedGolf) => restaurant?.value
            )
            : [],
        superUserWellness: selectedWellnessValue?.length ?
            selectedWellnessValue?.map((restaurant: any) => restaurant?.value)
            : [],

        superUserHotels: selectedHotelValue?.length
            ? selectedHotelValue?.map(
              (restaurant: any) => restaurant?.value
            )
            : [],
      };

      const selectedHotelIds = selectedHotelValue?.map((id: any) => id?.value);
      const selectedGolfsIds = selectedGolfs?.map((id: any) => id?.value);
      const selectedWellnessIds = selectedWellnessValue?.map((id: any) => id?.value);
      const selectedRestaurantIds = selectedRestaurantValue?.map((id: any) => id?.value);

      const isGolfChanged = editData?.manageGolfs?.length > 0 ? selectedGolfsIds?.length == 0 ? true : selectedGolfsIds?.some((catId: any) => !editData?.manageGolfs?.includes(catId)) : selectedGolfs?.length > 0
      const isHotelChanged = editData?.manageHotels?.length > 0 ? selectedHotelIds?.length == 0 ? true : selectedHotelIds?.some((catId: any) => !editData?.manageHotels?.includes(catId)) : selectedHotelValue?.length > 0
      const isRestaurantChanged = editData?.manageRestaurants?.length > 0 ? selectedRestaurantIds?.length == 0 ? true : selectedRestaurantIds?.some((catId: any) => !editData?.manageRestaurants?.includes(catId)) : selectedRestaurantValue?.length > 0
      const isWellnessChanged = editData?.manageWellness?.length > 0 ? selectedWellnessIds?.length == 0 ? true : selectedWellnessIds?.some((catId: any) => !editData?.manageWellness?.includes(catId)) : selectedWellnessValue?.length > 0

      const isSuperUserGolfChanged = editData?.superUserGolfs?.length > 0 ? selectedGolfsIds?.length == 0 ? true : selectedGolfsIds?.some((catId: any) => !editData?.superUserGolfs?.includes(catId)) : selectedGolfs?.length > 0
      const isSuperUserHotelChanged = editData?.superUserHotels?.length > 0 ? selectedHotelIds?.length == 0 ? true : selectedHotelIds?.some((catId: any) => !editData?.superUserHotels?.includes(catId)) : selectedHotelValue?.length > 0
      const isSuperUserRestaurantChanged = editData?.superUserRestaurants?.length > 0 ? selectedRestaurantIds?.length == 0 ? true : selectedRestaurantIds?.some((catId: any) => !editData?.superUserRestaurants?.includes(catId)) : selectedRestaurantValue?.length > 0
      const isSuperUserWellnessChanged = editData?.superUserWellness?.length > 0 ? selectedWellnessIds?.length == 0 ? true : selectedWellnessIds?.some((catId: any) => !editData?.superUserWellness?.includes(catId)) : selectedWellnessValue?.length > 0
      let filteredBodyParams = { ...bodyParams };

      if ((isAdmin ? window.location.pathname == "/super-user" : isForMakeSuperUser ? (window.location.pathname == "/app-user/app-customer" || window.location.pathname == "/app-user/app-admin") : window.location.pathname == "/app-user/super-user")) {
        if (filteredBodyParams?.manageHotels?.length > 0) filteredBodyParams.manageHotels = [];
        if (filteredBodyParams?.manageWellness?.length > 0) filteredBodyParams.manageWellness = [];
        if (filteredBodyParams?.manageRestaurants?.length > 0) filteredBodyParams.manageRestaurants = [];
        if (filteredBodyParams?.manageGolfs?.length > 0) filteredBodyParams.manageGolfs = [];
      }
      else {
        if (filteredBodyParams?.superUserGolfs?.length > 0) filteredBodyParams.superUserGolfs = [];
        if (filteredBodyParams?.superUserRestaurants?.length > 0) filteredBodyParams.superUserRestaurants = [];
        if (filteredBodyParams?.superUserWellness?.length > 0) filteredBodyParams.superUserWellness = [];
        if (filteredBodyParams?.superUserHotels?.length > 0) filteredBodyParams.superUserHotels = [];
      }

      let editBodyParams = {
        userId: editData ? editData?._id : undefined,
        firstName: values?.firstName ?? "",
        lastName: values?.lastName ?? "",
        mobileNumber: values?.mobileNumber
          ? values?.mobileNumber?.slice(`${values?.countryCode?.length}`)
          : "",
        countryCode:
          values?.countryCode != null && values?.countryCode !== ""
            ? !values?.countryCode.includes("+")
              ? `+${values?.countryCode}`
              : values?.countryCode
            : "1",
        email: values?.email ?? "",
        type: (isAdmin ? window.location.pathname == "/super-user" : isForMakeSuperUser ? (window.location.pathname == "/app-user/app-customer" || window.location.pathname == "/app-user/app-admin") : window.location.pathname == "/app-user/super-user") ? "superUser" : "admin",
        // role: ["superAdmin"],
        ...(!isForEditProfile ? { role: isAnyCategorySelected ? userRoles :  isForMakeSuperUser ? (window.location.pathname == "/app-user/app-customer" || window.location.pathname == "/app-user/app-admin") : window.location.pathname == "/app-user/super-user" ? userRoles?.filter((role: any) => role != "superUser") : ["user"] } : { role: roleValue }),
        ...(isGolfChanged && !(isAdmin ? window.location.pathname == "/super-user" : isForMakeSuperUser ? (window.location.pathname == "/app-user/app-customer" || window.location.pathname == "/app-user/app-admin") : window.location.pathname == "/app-user/super-user") &&{
          manageGolfs: isForEditProfile
            ? editData.manageGolfs
            : selectedGolfs?.length
              ? selectedGolfs?.map((golf: ISelectedGolf) => golf?.value)
              : [],
        }),
        ...(isRestaurantChanged && !(isAdmin ? window.location.pathname == "/super-user" : isForMakeSuperUser ? (window.location.pathname == "/app-user/app-customer" || window.location.pathname == "/app-user/app-admin") : window.location.pathname == "/app-user/super-user") &&{
          manageRestaurants: isForEditProfile
            ? editData.manageRestaurants
            : selectedRestaurantValue?.length
              ? selectedRestaurantValue?.map(
                (restaurant: ISelectedGolf) => restaurant?.value
              )
              : [],
        }),
        ...(isWellnessChanged && !(isAdmin ? window.location.pathname == "/super-user" : isForMakeSuperUser ? (window.location.pathname == "/app-user/app-customer" || window.location.pathname == "/app-user/app-admin") : window.location.pathname == "/app-user/super-user") &&{
          manageWellness:
            isForEditProfile ? editData.manageWellness : selectedWellnessValue?.length ?
              selectedWellnessValue?.map((restaurant: any) => restaurant?.value)
              : [],
        }),
        ...(isHotelChanged && !(isAdmin ? window.location.pathname == "/super-user" : isForMakeSuperUser ? (window.location.pathname == "/app-user/app-customer" || window.location.pathname == "/app-user/app-admin") : window.location.pathname == "/app-user/super-user") && {
          manageHotels: isForEditProfile
            ? editData.manageHotels
            : selectedHotelValue?.length
              ? selectedHotelValue?.map(
                (restaurant: any) => restaurant?.value
              )
              : [],
        }),
        ...(isSuperUserGolfChanged && (isAdmin ? window.location.pathname == "/super-user" : isForMakeSuperUser ? (window.location.pathname == "/app-user/app-customer" || window.location.pathname == "/app-user/app-admin") : window.location.pathname == "/app-user/super-user") && {
          superUserGolfs: selectedGolfs?.length
            ? selectedGolfs?.map((golf: ISelectedGolf) => golf?.value)
            : [],
        }),
        ...(isSuperUserRestaurantChanged && (isAdmin ? window.location.pathname == "/super-user" : isForMakeSuperUser ? (window.location.pathname == "/app-user/app-customer" || window.location.pathname == "/app-user/app-admin") : window.location.pathname == "/app-user/super-user") && {
          superUserRestaurants: selectedRestaurantValue?.length
            ? selectedRestaurantValue?.map(
              (restaurant: ISelectedGolf) => restaurant?.value
            )
            : [],
        }),
        ...(isSuperUserWellnessChanged && (isAdmin ? window.location.pathname == "/super-user" : isForMakeSuperUser ? (window.location.pathname == "/app-user/app-customer" || window.location.pathname == "/app-user/app-admin") : window.location.pathname == "/app-user/super-user") && {
          superUserWellness: selectedWellnessValue?.length ?
            selectedWellnessValue?.map((restaurant: any) => restaurant?.value)
            : [],
        }),
        ...(isSuperUserHotelChanged && (isAdmin ? window.location.pathname == "/super-user" : isForMakeSuperUser ? (window.location.pathname == "/app-user/app-customer" || window.location.pathname == "/app-user/app-admin") : window.location.pathname == "/app-user/super-user") && {
          superUserHotels: selectedHotelValue?.length
            ? selectedHotelValue?.map(
              (restaurant: any) => restaurant?.value
            )
            : [],
        })
      };

      if(isAnyCategorySelected){
        
      if (editData && !isForMakeSuperUser) {
        let response = await editAPI(editBodyParams);
        if (response?.status) {
          snackActions.success(
            response?.message ?? "Added/Edited Successfully!"
          );
          formik.resetForm();
          if (isForEditProfile) {
            localStorage.setItem("userInfo", JSON.stringify(response?.data));
          }
          handleClose(true);
        } else {
          snackActions.error(response?.message ?? "Something went wrong!");
        }
      } else {
        let response = await addAPI(filteredBodyParams);
        if (response?.status) {
          snackActions.success(
            response?.message ?? "Added/Edited Successfully!"
          );
          formik.resetForm();
          handleClose(true);
        }
      }
    }
    },
  });

  const { mutateAsync: addAPI, isLoading: isAdding } = useMutation(
    "add-edit",
    addAdmin
  );
  const { mutateAsync: editAPI, isLoading: isEditing } = useMutation(
    "add-edit",
    editAdmin
  );

  useEffect(() => {
    if (editData) {
      formik.setFieldValue(
        "mobileNumber",
        editData?.countryCode + editData?.mobileNumber
      );
    }
  }, [editData]);

  const adminCategory : any= localStorage.getItem("userInfo")
  const convertedAdminCategory = JSON.parse(adminCategory)
  const isAdminAssociatedWithGolf = convertedAdminCategory?.manageGolfs?.length > 0
  const isAdminAssociatedWithHotel = convertedAdminCategory?.manageHotels?.length > 0
  const isAdminAssociatedWithWellness = convertedAdminCategory?.manageWellness?.length > 0
  const isAdminAssociatedWithRestaurant = convertedAdminCategory?.manageRestaurants?.length > 0

  const isShowLoader = isLoadingRestaurant && isLoadingWellness && isGettingGolfData && isGettingHotelData

  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-700px"
      show={show}
      onHide={() => handleClose(false)}
      backdrop="static"
    >
      <div className="modal-header">
        {isForMakeSuperUser ? <h2>Make a Concierge user</h2>
        : <h2>{editData?._id ? "Edit" : "Add"} {isAdmin || window.location.pathname == "/app-user/super-user" ? "Concierge User" : "Admin"}</h2>}
        {/* begin::Close */}
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={() => handleClose(false)}
        >
          <KTIcon className="fs-1" iconName="cross" />
        </div>
        {/* end::Close */}
      </div>

      <div className="p-6">
        <form onSubmit={formik.handleSubmit} noValidate className="form">
        {isShowLoader && (
        <Box className="spinner-loader">
          <CircularProgress />
        </Box>
      )}
          <Container fluid>
            <Row>
              <Col xs={6}>
                <label className="form-label fw-bold">First Name{''} <span className="required-icon">*</span></label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="First Name"
                  aria-label="First Name"
                  {...formik.getFieldProps("firstName")}
                />
                {formik.touched.firstName && formik.errors.firstName && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.firstName}
                    </div>
                  </div>
                )}
              </Col>
              <Col xs={6}>
                <label className="form-label fw-bold">Last Name{''} <span className="required-icon">*</span></label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Last Name"
                  aria-label="Last Name"
                  {...formik.getFieldProps("lastName")}
                />
                {formik.touched.lastName && formik.errors.lastName && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.lastName}
                    </div>
                  </div>
                )}
              </Col>
            </Row>

            <Row className="pt-6">
              <Col xs={6}>
                <label className="form-label fw-bold">Email{''} <span className="required-icon">*</span></label>
                <input
                  placeholder="Email"
                  aria-label="Email"
                  {...formik.getFieldProps("email")}
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  type="email"
                  name="email"
                  autoComplete="off"
                  disabled={editData ? true : false}
                  style={{
                    cursor: editData ? "not-allowed" : "initial",
                  }}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.email}</div>
                  </div>
                )}
              </Col>
              <Col xs={6}>
                <label className="form-label fw-bold">Mobile Number{''} <span className="required-icon">*</span></label>
                <PhoneInput
                  country={"us"}
                  enableSearch={true}
                  // variant="outlined"
                  onBlur={formik.handleBlur}
                  value={formik.values.mobileNumber}
                  onChange={(value: string, country: any) => {
                    formik.setFieldValue(
                      "countryCode",
                      country?.dialCode || ""
                    );
                    formik.setFieldValue("mobileNumber", value || "");
                  }}
                  inputStyle={{
                    background: "#f9f9f9",
                    border: "none",
                  }}
                  inputClass="form-control form-control-lg form-control-solid mb-3 mb-lg-0 w-100"
                  // disableDropdown={true}
                  // disableCountryCode={true}
                  // onlyCountries={["us"]}
                  disabled={editData ? true : false}
                />
                {formik.touched.mobileNumber && formik.errors.mobileNumber && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.mobileNumber}
                    </div>
                  </div>
                )}
              </Col>
            </Row>
            <Row className="pt-6">
              {(isAdmin ? isAdminAssociatedWithRestaurant : true) && (<Col xs={6}>
                <label className="form-label fw-bold">Assign Restaurants</label>
                <MultiSelect
                  options={
                    restaurantData && !isLoadingRestaurant
                      ? restaurantData?.data?.docs?.map((d: any) => {
                        return { label: d?.name, value: d?._id };
                      })
                      : []
                  }
                  value={selectedRestaurantValue}
                  onChange={(value: any) => {
                    setSelectedRestaurantValue(value);
                    formik.setFieldValue('assignedRestaurants', value);
                  }}
                  labelledBy="Select"
                  className="form-control form-control-lg form-control-solid p-1"
                />
                {/* {!isForEditProfile &&
                  formik.touched.manageRestaurants &&
                  !selectedRestaurantValue?.length && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        Restaurant selection is required
                      </div>
                    </div>
                  )} */}
              </Col>)}
              {(isAdmin ? isAdminAssociatedWithGolf : true) && (<Col xs={6}>
                <label className="form-label fw-bold">Assign Golfs</label>
                <MultiSelect
                  options={
                    golfData && !isGettingGolfData
                      ? golfData?.data?.docs?.map((d: any) => {
                        return { label: d?.name, value: d?._id };
                      })
                      : []
                  }
                  value={selectedGolfs}
                  onChange={(value: any) => {
                    setSelectedGolf(value);
                    formik.setFieldValue('assignedGolfs', value);
                  }}
                  labelledBy="Select"
                  className="form-control form-control-lg form-control-solid p-1"
                />
                {/* {!isForEditProfile &&
                  formik.touched.manageGolfs &&
                  !selectedGolfs?.length && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        Golf selection is required
                      </div>
                    </div>
                  )} */}
              </Col>)}


            </Row>
            <Row className="pt-6">
            {(isAdmin ? isAdminAssociatedWithWellness : true) && (<Col xs={6}>
                <label className="form-label fw-bold">Assign Wellness</label>
                <MultiSelect
                  options={
                    wellnessDropdownData
                      ? wellnessDropdownData?.data?.docs?.map((d: any) => {
                        return { label: d?.name, value: d?._id };
                      })
                      : []
                  }
                  value={selectedWellnessValue}
                  onChange={(value: any) => {
                    setSelectedWellnessValue(value);
                    formik.setFieldValue('assignedWellness', value);
                  }}
                  labelledBy="Select"
                  className="form-control form-control-lg form-control-solid p-1"
                />
              </Col>)}
              {(isAdmin ? isAdminAssociatedWithHotel : true) && (<Col xs={6}>
                <label className="form-label fw-bold">Assign Hotel</label>
                <MultiSelect
                  options={
                    hotelnessDropdownData
                      ? hotelnessDropdownData?.data?.docs?.map((d: any) => {
                        return { label: d?.name, value: d?._id };
                      })
                      : []
                  }
                  value={selectedHotelValue}
                  onChange={(value: any) => {
                    setSelectedHotelValue(value);
                    formik.setFieldValue('assignedHotels', value);
                  }}
                  labelledBy="Select"
                  className="form-control form-control-lg form-control-solid p-1"
                />
                {/* {!isForEditProfile &&
                  formik.touched.manageGolfs &&
                  !selectedGolfs?.length && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        Golf selection is required
                      </div>
                    </div>
                  )} */}
              </Col>)}
            </Row>

            {/* {isCategorySelected != "" && !(selectedWellnessValue.length > 0 || selectedGolfs.length > 0 || selectedHotelValue.length > 0 || selectedRestaurantValue.length > 0) && (
                  <Col xs={12}>
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {isCategorySelected}
                      </div>
                    </div>
                  </Col>
                )} */}
                {formik.touched.assignedRestaurants && formik.errors.assignedRestaurants && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.assignedRestaurants}
                    </div>
                  </div>
                )}
          </Container>
          <div className="w-100 pt-5 d-flex align-items-center justify-content-end mt-3">
            <Button
              onClick={() => {
                formik.resetForm();
                handleClose(false);
              }}
              size="lg"
              variant="secondary"
            >
              Cancel
            </Button>
            <div className="w-15px"></div>
            <Button type="submit" size="lg" variant="primary">
              {isAdding || isEditing ? "Loading..." : "Submit"}
            </Button>
          </div>
        </form>
      </div>
    </Modal>,
    modalsRoot
  );
};

export default AddEditAdmin;
